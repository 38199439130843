import React from 'react'
import randomRelatable from './randomRelatable';

/* Footer for pages where user isn't logged in */

class Footer extends React.Component {
    render() {
        return (
            <div>
                {this.props.absolute === true &&
                    <div id="footer_absolute">
                        <div id='footer_random'>
                            Roomble is...{randomRelatable()}
                        </div>
                        <div id='footer_body'>
                            created with ♡ for students by students
                        </div>
                    </div>
                }

                {this.props.absolute === false &&
                    <div id="footer_relative">
                        <div id='footer_random'>
                            Roomble is...{randomRelatable()}
                        </div>
                        <div id='footer_body'>
                            created with ♡ for students by students
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default Footer;
