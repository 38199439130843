import { React, useEffect } from 'react';
import ReactGA from 'react-ga4';

/* Renders page when user successfully validates email with the link */

function UpcomingSchoolPage(props) {
    useEffect(() => {
        async function updateAnalytics() {
            ReactGA.event({
                category: "Signup Process",
                action: "View Upcoming School Page",
                nonInteraction: false, // optional, true/false
            });
        }
        updateAnalytics();
        document.title = 'Upcoming School';
    }, [])

    return (
        <div>
            <div>{props.changePage('upcomingschool')}</div>
            <div id="highlighted_card">
                <div className='informational_header'> Roomble Campuses get instanct access! </div>
                <div className='informational_text'> Your school isn’t a Roomble Campus. But you can change that!</div>
            </div>

            <div className='informational_spacer'></div>

            <div id="highlighted_card">
                <div className='informational_header'> Frequently Asked Questions </div>
                <div className='informational_text' id="upcoming_school_header"> <u>What is a Roomble Campus?</u> </div>
                <div className='informational_text' id="upcoming_school_text"> Roomble campuses are schools with 5+ signups on Roomble.org</div>
                <div className='informational_text' id="upcoming_school_header"> <u>How does my school become a Roomble Campus?</u> </div>
                <div className='informational_text' id="upcoming_school_text"> Just gather 4 friends going to your university and have them enter their emails on Roomble.org! </div>
                <div className='informational_spacer'></div>
                <button className='default_roomble_button' onClick={async () => {
                    if (navigator.clipboard) {
                        await navigator.clipboard.writeText('https://roomble.org')
                        alert('Link copied to clipboard!');
                        ReactGA.event({
                            category: "Referrals",
                            action: "Upcoming School Refer Button Click",
                            nonInteraction: false, // optional, true/false
                        });
                    } else {
                        alert('Copying to clipboard not supported on this browser.');
                    }
                }}> Share With a Friend! </button>
            </div>

            <div className='informational_spacer'></div>
        </div>
    )
}

export default UpcomingSchoolPage;