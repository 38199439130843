import React from 'react';
import { Alert } from 'react-bootstrap'
import axios from 'axios';
import ReactGA from "react-ga4";
import config from '../config.js';

import { getRoom, getBuilding, isHousingInstitution } from "../utils.js"
/* Renders the edit user basic info form component */

/*
  steps to re add building
  1) use the old axios call (they're both below)
  2) uncomment the part of validate input that checks for building
*/

class BasicInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user_fname: '',
      user_lname: '',
      sex: '-1',
      age: '', // age is graduation year
      pronouns: '',
      contact_info: '',
      contact_location: '-1',
      building: "-2",
      room_type: "-2",
      displayError: false,
      displayUsernameError: false,
      institution: "",
    };

    /* If the component is passed old answers render with those already in place */
    if (props.hasOwnProperty("old_answers") && props.old_answers !== "") {
      this.state = {
        ...this.state,
        user_fname: props.old_answers.first_name,
        user_lname: props.old_answers.last_name,
        sex: props.old_answers.sex,
        age: props.old_answers.age,
        pronouns: props.old_answers.pronouns,
        contact_info: props.old_answers.email.split(":")[1].trim(),
        contact_location: props.old_answers.email.split(":")[0],
        building: props.old_answers.building + '',
        room_type: props.old_answers.room_type + '',
        institution: props.old_answers.institution + '',
      }
    }
    else {
      this.state = {
        ...this.state,
        institution: localStorage.getItem("institution"),
      }
      localStorage.removeItem("institution");
    }


    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
    this.validateInput = this.validateInput.bind(this);
    this.hideError = this.hideError.bind(this);
    this.hideUsernameError = this.hideUsernameError.bind(this);
  }

  /* Update state with new value for each field */
  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      ...this.state,
      [name]: value
    });
  }

  /* Checks each input field is filled out */
  validateInput(input) {
    for (let key in input) {
      let value = input[key];
      if (key === 'sex') {
        if (value === -1) return false;
      }
      else if (key === 'age') {
        if (value < 2024) return false;
        if (value > 2029) return false;
      }
      else if (isHousingInstitution(this.state.institution) && (key === 'building' || key === 'room_type')) {
        if (parseInt(value) === -2) return false;
      } else {
        if (value === '' || value == null) return false;
      }
    }
    return true;
  }

  async submit() {
    // fix inputs
    const input = this.state;
    input.sex = parseInt(this.state.sex);
    input.age = parseInt(this.state.age);
    input.building = parseInt(this.state.building);
    input.room_type = parseInt(this.state.room_type);

    // validate
    if (this.validateInput(input)) {
      // if good then send
      var result = await updateBasicInfo(input.user_fname, input.user_lname, input.age,
        input.sex, input.pronouns, input.contact_location, input.contact_info, input.building, input.room_type);
      if (result === false) {
        this.setState({
          displayUsernameError: true,
        })
      } else {
        ReactGA.event({
          category: "Update Profile",
          action: "Facts And Figures Update",
          nonInteraction: false,
        });
        if (localStorage.getItem("first signup") === "true")
          window.location.href = `${window.location.origin}/lifestylesurvey`;
        else
          window.location.href = `${window.location.origin}/userprofile`;
      }
    } else {
      // if not error message
      this.setState({
        ...this.state,
        displayError: true,
      })
    }
  }

  hideUsernameError() {
    this.setState({
      displayUsernameError: false,
    });
  }

  hideError() {
    this.setState({
      ...this.state,
      displayError: false,
    })
  }

  render() {
    return (
      <div>
        {(this.state.displayError) &&
          <Alert variant='danger' id="basic_info_alert" dismissible onClose={this.hideError}>
            Please answer all the questions below and ensure your answers are correct
          </Alert>}
        {(this.state.displayUsernameError) &&
          <Alert variant='danger' id="basic_info_alert" dismissible onClose={this.hideUsernameError}>
            Sorry this username or email address is taken
          </Alert>}

        <form>
          <div className='survey_form_item'>
            <input
              type="text"
              value={this.state.user_fname}
              onChange={this.handleChange}
              name="user_fname"
              placeholder="First Name"
              class="default_roomble_input_box" />
          </div>

          <div class="form-group" className='survey_form_item'>
            <input
              type="text"
              value={this.state.user_lname}
              onChange={this.handleChange}
              name="user_lname"
              placeholder="Last Name"
              class="default_roomble_input_box"
              id="exampleFormControlInput1" />
          </div>

          <div class="form-group" className='survey_form_item'>
            <input
              type="number"
              min="2024" step="1"
              default="2027"
              value={this.state.age}
              onChange={this.handleChange}
              name="age"
              placeholder="Graduation Year"
              class="default_roomble_input_box"
              id="exampleFormControlInput1" />
          </div>

          <div class="form-group" className='survey_form_item'>
            <select
              class="default_roomble_input_box"
              value={this.state.sex}
              onChange={this.handleChange}
              name="sex"
            >
              <option value='-1' selected disabled>Sex assigned at birth</option>
              <option value='0'> Male </option>
              <option value='1'> Female </option>
            </select>
          </div>

          <div class="form-group" className='survey_form_item'>
            <input
              type="text"
              value={this.state.pronouns}
              onChange={this.handleChange}
              name="pronouns"
              placeholder="Pronouns"
              className="default_roomble_input_box"
              id="exampleFormControlInput1" />
            <div className="signup_subtext"></div>
          </div>

          {isHousingInstitution(this.state.institution) &&
            <div>
              <div className="signup_subtext" id="basic_info_helptext"> On Campus Housing Details: select undeclared if unassigned or unsure </div>

              <div class="form-group" className='survey_form_item'>
                <select
                  class="default_roomble_input_box"
                  value={this.state.building}
                  onChange={this.handleChange}
                  name="building"
                >
                  <option value="-2" disabled={this.state.building !== "-2"} >Building</option>
                  {getBuilding(this.state.institution)}
                </select>
              </div>
              <div class="form-group" className='survey_form_item'>
                <select
                  class="default_roomble_input_box"
                  value={this.state.room_type}
                  onChange={this.handleChange}
                  name="room_type"
                >
                  <option value="-2" disabled={this.state.building !== "-2"}>Room Type</option>
                  {getRoom(this.state.institution)}
                </select>
              </div>
            </div>
          }

          <div className="signup_subtext" id="basic_info_helptext"> Info below won't show until you match </div>

          <div class="form-group" className='survey_form_item'>
            <select
              class="default_roomble_input_box"
              value={this.state.contact_location}
              onChange={this.handleChange}
              name="contact_location"
            >
              <option value='-1' selected disabled> Social Media Contact </option>
              <option value='Instagram'> Instagram </option>
              <option value='Snapchat'> Snapchat </option>
              <option value='Facebook'> Facebook </option>
              <option value='Email'> Email </option>
              <option value='Phone'> Phone </option>
            </select>
          </div>

          <div class="form-group" className='survey_form_item'>
            <input
              type="text"
              value={this.state.contact_info}
              onChange={this.handleChange}
              name="contact_info"
              placeholder="Social Media Username"
              className="default_roomble_input_box"
              id="exampleFormControlInput1"
            />
          </div>

          <div id='basic_info_spacer'></div>

          <div>
            <input
              className="default_roomble_button"
              name="submit"
              type="button"
              disabled={!this.state.user_fname || !this.state.user_lname ||
                !this.state.age || parseInt(this.state.sex) === -1
                || !this.state.pronouns || parseInt(this.state.contact_location) === -1 ||
                (isHousingInstitution(this.state.institution) && (parseInt(this.state.building) === -2 || parseInt(this.state.room_type) === -2))}
              value="Next"
              onClick={this.submit}>
            </input>
          </div>
        </form>
      </div>
    );
  }
}

/* Calls backend with updated basic info */
async function updateBasicInfo(firstName, lastName, age, sex, pronouns, contact_location, contact_info, building, room_type) {
  try {
    var res = await axios.post(`${config.host}/UpdateProfilePost`, {
      "first_name": firstName, "last_name": lastName, "age": age.toString(), "sex": sex.toString(), "pronouns": pronouns, "email": `${contact_location}: ${contact_info}`,
      "building": building.toString(), "room_type": room_type.toString()
    }, {
      headers: {
        roomies_auth_token: localStorage.getItem("roomies_auth_token"),
      },
    });
    return true;
  } catch (err) {
    console.log("failed to create user");
    return false;
  }
}

/* Calls backend with user auth token to find if account has completed signup process */
async function getAccountStatus() {
  console.log("hit");
  try {
    var status = await axios.get(`${config.host}/GetAccountStatus`, {
      headers: {
        roomies_auth_token: localStorage.getItem("roomies_auth_token"),
      },
    });
    return status.data;
  } catch (err) {
    console.log("failed to create user");
    return false;
  }
}

export default BasicInfo