import React from 'react'
import { Link } from 'react-router-dom'

/* Navigation bar component to display at top when logged in, link to pages */

class TopNavbar extends React.Component {
  render() {
    return (
      <div>
        <div id="top_navbar">
          <a href='mailto:roomble.customer.service@gmail.com' className='top_navbar_item'> Contact </a>
          <span id="top_navbar_brand_text"> Roomble </span>
          <Link to="/login" className="top_navbar_item" onClick={() => { localStorage.removeItem('roomies_auth_token'); this.props.logOut(-1); }}>Logout</Link>
        </div>

        <div id="top_navbar_space"></div>
      </div>
    );
  }
}

export default TopNavbar;
