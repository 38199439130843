import roomMapper from './jsons/roomTypes.json';
import buildingMapper from './jsons/buildings.json';

/* Dynamically populate select options for room */
export function getRoom(institution) {
    // Convert the original data to the required format
    const roomData = roomMapper[institution];
    const formattedData = Object.entries(roomData).map(([value, name]) => ({ name, value }));

    return formattedData.map((room) => {
      return <option key={room.value} value={room.value}>{room.name}</option>;
    });
}

/* Dynamically populate select options for building */
export function getBuilding(institution) {
    // Convert the original data to the required format
    const buildingData = buildingMapper[institution];
    const formattedData = Object.entries(buildingData).map(([value, name]) => ({ name, value }));
 
    return formattedData.map((building) => {
      return <option key={building.value} value={building.value}>{building.name}</option>;
     });
}

/* Returns true if an institution has housing filters */
export function isHousingInstitution(institution) {
    institution = parseInt(institution);
    return institution === 1; // UCLA
  }
 