import { useState, useEffect } from "react";
import Profile from '../Components/Profile.js'
import axios from 'axios'
import { Jelly } from '@uiball/loaders';
import ReactGA from "react-ga4";
import config from '../config.js';

const defaultUser = {
  user_id: 0,
  first_name: "Aidan",
  last_name: "Wittenberg",
  gender: "he/him",
  age: "20",
  biography: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, " +
    "sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
};

/* Gets potential match data then renders swiping page which utilizes the profile component */
function SwipingPage(props) {
  let [users, setUsers] = useState([defaultUser]);  // Array of user profiles
  let [pendingMatchUsers, setPendingMatchUsers] = useState(new Set());  // Array of user profiles
  let [curnUser, setCurnUser] = useState(users[0]); // Current profile being shown
  let [surveys, setSurveys] = useState([]); // Array of all full surveys
  let [numUsersToSwipe, setNumUsersToSwipe] = useState(0);  // Number of users left to swipe on
  let [surveyRankingMap, setSurveyRankingMap] = useState({})  // Map of user ID to survey based on compatibility
  let [curnSurvey, setCurnSurvey] = useState(null); // Current full survey for shown profile
  let [curnRankedSurvey, setCurnRankedSurvey] = useState(null); // Current compatible survey for profile being shown
  let [displayUsers, setDisplayUsers] = useState(true); // Bool whether to show users
  let [renderAll, setRenderAll] = useState(false);  // Bool to track whether to show loading
  let [displayFullSurvey, setDisplayFullSurvey] = useState(false);  // Bool to track showing full survey or compatible survey

  useEffect(() => {
    async function initializePage() {
      // GetMatches userIds
      let userIdsAll = await getFilteredMatches();
      if (userIdsAll == null) userIdsAll = [];
      let userIds = userIdsAll.slice(0, 50);  // Only show 50 users at a time
      let userIdNums = userIds.map(str => parseInt(str));
      setNumUsersToSwipe(userIdNums.length);

      // Get pending matches
      let userIdsPendingAll = await getFilteredPendingMatches();
      if (userIdsPendingAll == null) userIdsPendingAll = [];
      let userIdsPendingAllSet = new Set(userIdsPendingAll);
      setPendingMatchUsers(userIdsPendingAllSet);

      // Get profiles from userIds
      let userProfiles = await getProfiles(userIdNums);
      if (userProfiles == null) setUsers([]);
      else setUsers(userProfiles);

      // Get survyes
      let new_surveys = await getSurveyAnswers(userIdNums);
      setSurveys(new_surveys);

      // Get and arrange ranked surveys
      let ranked_surveys_batched = await getOrderedSurveyAnswersBatched(userIdNums)
      if (ranked_surveys_batched == null) ranked_surveys_batched = [];
      let ranked_surveys_map = {}
      for (let i = 0; i < ranked_surveys_batched.length; i++) {
        ranked_surveys_map[ranked_surveys_batched[i]["user"]] = ranked_surveys_batched[i]["responses"]
      }
      setSurveyRankingMap(ranked_surveys_map)

      if (userIdNums.length > 0) {
        // Set the initial values for curnUser, curnSurvey, and curnRankedSurvey
        setCurnUser(userProfiles[0]);
        setCurnSurvey(new_surveys[0]);
        setCurnRankedSurvey(ranked_surveys_map[userProfiles[0].user_id]);

        // Set the initial values for the future swipes except the ones we just set
        let newUsers = [...userProfiles]
        newUsers.splice(0, 1);
        setUsers(newUsers);

        let newSurveys = [...new_surveys]
        newSurveys.splice(0, 1);
        setSurveys(newSurveys);

        setNumUsersToSwipe(num => num - 1);
      } else {
        turnOffUsers();
      }

      //Forces images to be loaded ahead so no delay when swiping
      let images = []
      for (let i = 0; i < userIds.length; i++) {
        const img = new Image();
        if (userProfiles[i].images.length > 0) {
          img.src = userProfiles[i].images[0]
          images.push(img)
        }
      }
      setRenderAll(true);
    }
    initializePage();
    document.title = 'Swipe';
  }, []);

  if (localStorage.getItem('roomies_auth_token') == null) {
    window.location.href = `${window.location.origin}/`;
    return;
  }

  function turnOffUsers() {
    setDisplayUsers(false);
    setCurnUser(defaultUser);
    setCurnSurvey(null);
    setCurnRankedSurvey(null);
  }

  function swapSurveyDisplay() {
    setDisplayFullSurvey(!displayFullSurvey)
  }

  /* Removes the first user from the array and sets the new first user to be shown */
  function iterateUsers() {
    if (users.length > 0 && surveys.length > 0 && numUsersToSwipe > 0) {
      setDisplayFullSurvey(false)

      let newUsers = [...users]
      newUsers.splice(0, 1);
      setUsers(newUsers);

      let newSurveys = [...surveys]
      newSurveys.splice(0, 1);
      setSurveys(newSurveys);

      setNumUsersToSwipe(num => num - 1);

      setCurnUser(users[0]);
      setCurnSurvey(surveys[0]);
      setCurnRankedSurvey(surveyRankingMap[users[0].user_id]);
    } else {
      turnOffUsers();
      setTimeout(window.location.reload(), 3000)
    }
  }

  function swipe_left() {
    iterateUsers()
    leftSwipe(curnUser.user_id); // call to backend
    ReactGA.event({
      category: "Swipe",
      action: "Left Swipe",
      nonInteraction: false, // optional, true/false
    });
  }

  function swipe_right() {
    iterateUsers()
    rightSwipe(curnUser.user_id); //call to backend
    if (pendingMatchUsers.has(curnUser.user_id)) {
      sendMatchEmail(curnUser.user_id);
    }
    else {
      sendRightSwipeEmail(curnUser.user_id);
    }
    ReactGA.event({
      category: "Swipe",
      action: "Right Swipe",
      nonInteraction: true, // optional, true/false
    });
  }

  return (
    <div>
      <div>{props.changePage('swipe')}</div>

      {!renderAll &&
        <div id="loader_outer_box">
          <div id="loader_item">
            <Jelly
              color="#2694C9"
              size={40}
            />
          </div>
        </div>
      }

      {(renderAll) &&
        <div>
          <div id="user_profile_page_box">
            <button
              id="choose_filter_button"
              onClick={() => { window.location.href = `${window.location.origin}/filter`; }}
            >
              Choose Filters
            </button>
          </div>
          {(displayUsers) &&
            <div>
              <Profile
                user={curnUser}
                answers={curnSurvey}
                rankedAnswers={curnRankedSurvey}
                swapSurveyDisplay={() => swapSurveyDisplay()}
                displayFullSurvey={displayFullSurvey}
                isProfilePage={false}
                isSwipePage={true}
                swipeRight={() => swipe_right()}
                swipeLeft={() => swipe_left()}
                isPendingMatch={pendingMatchUsers.has(curnUser.user_id)}
              />
            </div>
          }
          {(!displayUsers) &&
            <div id="no_swipes_available_page">
              <div id="card">
                <div className="card_header">
                  Matching Page
                </div>
                <div className="card_text">
                  It seems you've swiped to the end.
                  Check back soon for new friends!
                </div>
              </div>
            </div>
          }

          <div>
          </div>

          <div id="user_profile_page_box">
            <button
              id="feedback_button"
              onClick={() => { window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLSd6QBO0fzHAY4g03QuLYRn7iCeoJgUiHZKFXDRNorHmvUFAqQ/viewform?usp=sf_link'; }}
            >
              Tell us about your experience!
            </button>
          </div>
        </div>
      }
    </div>
  )
}

async function getFilteredMatches() {
  try {
    //map true/false grad year array to years
    let graduationYear = JSON.parse(localStorage.getItem("filters_grad_years"));
    let age_filter = [];

    if (graduationYear != null) {
      for (let i = 0; i < 4; i++) {
        if (graduationYear[i] === true) {
          age_filter.push(2025 + i);
        }
      }
    }
    if (age_filter.length === 0) // not there or all false
      age_filter = [2025, 2026, 2027, 2028];

    var res = await axios.post(`${config.host}/GetFilteredPossibleMatches`, { "age": age_filter }, {
      headers: {
        roomies_auth_token: localStorage.getItem("roomies_auth_token")
      }
    })
    return res.data;
  } catch (err) {
    console.log(err);
    return null;
  }
}

async function getFilteredPendingMatches() {
  try {
    //map true/false grad year array to years
    let graduationYear = JSON.parse(localStorage.getItem("filters_grad_years"));
    let age_filter = [];

    if (graduationYear != null) {
      for (let i = 0; i < 4; i++) {
        if (graduationYear[i] === true) {
          age_filter.push(2025 + i);
        }
      }
    }
    if (age_filter.length === 0) // not there or all false
      age_filter = [2025, 2026, 2027, 2028];

    var res = await axios.post(`${config.host}/GetFilteredPendingMatches`, { "age": age_filter }, {
      headers: {
        roomies_auth_token: localStorage.getItem("roomies_auth_token")
      }
    })
    return res.data;
  } catch (err) {
    console.log(err);
    return null;
  }
}

async function getProfiles(userIds) {
  try {
    let res = await axios.post(`${config.host}/GetProfiles`, { "user_ids": userIds }, {
      headers: {
        roomies_auth_token: localStorage.getItem("roomies_auth_token"),
      },
    });
    return res.data;
  } catch (err) {
    console.log(err);
    return null;
  }
}

async function rightSwipe(matched_user) {
  try {
    var res = await axios.get(`${config.host}/RightSwipe?matched_user=${matched_user}`, {
      headers: {
        roomies_auth_token: localStorage.getItem("roomies_auth_token")
      }
    });
  } catch (err) {
    console.log(err);
  }
}

async function leftSwipe(matched_user) {
  try {
    var res = await axios.get(`${config.host}/LeftSwipe?matched_user=${matched_user}`, {
      headers: {
        roomies_auth_token: localStorage.getItem("roomies_auth_token")
      }
    });
  } catch (err) {
    console.log(err);
  }
}

async function sendMatchEmail(userId) {
  try {
    let res = await axios.post(`${config.host}/SendPromotionalEmail`, { "user_id": userId, "type": "match" }, {
      headers: {
        roomies_auth_token: localStorage.getItem("roomies_auth_token"),
      },
    });
    return res.data;
  } catch (err) {
    console.log(err);
    return null;
  }
}

async function sendRightSwipeEmail(userId) {
  try {
    let res = await axios.post(`${config.host}/SendPromotionalEmail`, { "user_id": userId, "type": "rightSwipe" }, {
      headers: {
        roomies_auth_token: localStorage.getItem("roomies_auth_token"),
      },
    });
    return res.data;
  } catch (err) {
    console.log(err);
    return null;
  }
}

async function getSurveyAnswers(matches_list) {
  try {
    let res = await axios.post(`${config.host}/GetSurveyResponses`, { "user_ids": matches_list }, {
      headers: {
        roomies_auth_token: localStorage.getItem("roomies_auth_token"),
      },
    });
    return res.data;
  } catch (err) {
    console.log(err);
    return null;
  }
}

async function getOrderedSurveyAnswersBatched(userIds) {
  try {
    let res = await axios.post(`${config.host}/GetBatchedCompatibleSurveyAnswers`, { "user_ids": userIds }, {
      headers: {
        roomies_auth_token: localStorage.getItem("roomies_auth_token"),
      },
    });
    return res.data;
  } catch (err) {
    console.log(err);
    return null;
  }
}

export default SwipingPage;
