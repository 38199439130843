import { React, useEffect } from 'react';
import ReactGA from 'react-ga4';

/* Renders page when user successfully validates email with the link */

function EmailValidatedFailPage(props) {
    useEffect(() => {
        async function updateAnalytics() {
            ReactGA.event({
                category: "Signup Process",
                action: "Email Validated Success",
                nonInteraction: false, // optional, true/false
            });
        }
        updateAnalytics();
        document.title = 'Email Validated Fail';
    }, [])
    function handleLoginButtonClick() {
        window.location.href = `${window.location.origin}/login`;
    }

    return (
        <div>
            <div>{props.changePage('emailvalidated')}</div>
            <div id="highlighted_card">
                <div className='informational_header'> Already Validated </div>
                <div className='informational_text'> You've already validated your email. </div>
                <div className='informational_text'>  If you're having trouble accessing your account, email us at roomble.customer.service@gmail.com</div>
                <div className='informational_text'> Login to get Roombling! </div>
                <div className='informational_spacer'></div>
                <button className='default_roomble_button' onClick={handleLoginButtonClick}> Login </button>
            </div>
        </div>
    )
}

export default EmailValidatedFailPage;