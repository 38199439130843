import React from 'react';
import { Alert } from 'react-bootstrap'
import axios from 'axios';
import ReactGA from "react-ga4";
import config from '../config.js';
import Checkbox from '../Components/Common/Checkbox/Checkbox.js'
/* Signup form to display on signup page */

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: localStorage.getItem('user_signup_email'), // in case they come from landing page
      confirmEmail: '',
      password: '',
      termsconditions: false,
      displayError: false
    };
    localStorage.removeItem('user_signup_email') // removes cookie so they can go from landing page again
    this.userChange = this.userChange.bind(this);
    this.emailConfirmChange = this.emailConfirmChange.bind(this);
    this.passwordChange = this.passwordChange.bind(this);
    this.termsconditionsChange = this.termsconditionsChange.bind(this);
    this.authenticate = this.authenticate.bind(this);
    this.hideError = this.hideError.bind(this);
  }

  // called when any change is made to email field input
  userChange(e) {
    e.preventDefault()
    this.setState({
      ...this.state,
      email: e.target.value
    });
  }

  // called when any change is made to email field input
  emailConfirmChange(e) {
    e.preventDefault()
    this.setState({
      ...this.state,
      confirmEmail: e.target.value
    });
  }

  // called when any change is made to password field input
  passwordChange(e) {
    e.preventDefault()
    this.setState({
      ...this.state,
      password: e.target.value
    });
  }

  // called when any change is made to terms and conditions field input
  termsconditionsChange(e) {
    this.setState({
      ...this.state,
      termsconditions: !this.state.termsconditions
    });
    console.log(this.state.termsconditions)
  }

  // called when duplicate email alert closed
  hideError() {
    this.setState({
      ...this.state,
      displayError: false,
    });
  }

  /* Called on signup submit - makes backend call and moves user to next page */
  async authenticate() {
    let email_lower = this.state.email.toLowerCase();

    if (!config.liveSchools.find(school => email_lower.endsWith(school.emailExtension))) {
      this.setState({
        ...this.state,
        displayError: true,
      });
      // log event
      ReactGA.event({
        category: "Signup Process",
        action: "Signup Invalid Email",
        nonInteraction: false, // optional, true/false
      });
      if (email_lower.endsWith(".edu")) {
        await AddToEmailList(this.state.email);
        window.location.href = `${window.location.origin}/upcomingschool`;
      }
      return;
    }

    // use axios to hit authenticate endpoint
    let success = (await Signup(this.state.email, this.state.password));
    if (!success) {
      //display wrong bc email already exists
      this.setState({
        ...this.state,
        displayError: true,
      });
    } else {
      // log event
      ReactGA.event({
        category: "Signup Process",
        action: "Send Verification Email",
        nonInteraction: false, // optional, true/false
      });
      // go to new page
      await AddToEmailList(this.state.email);
      window.location.href = `${window.location.origin}/validateemail`;
    }
  }

  render() {
    return (
      <div id='login_page'>
        <div id='login_page_arrangement'>
          {
            // displays error message if email already exists
            (this.state.displayError) &&
            <Alert variant="danger" onClose={this.hideError} className='signin_alert' dismissible>
              Please enter a valid school .edu email.
              If your email is valid, check to see if you've already created an account.
            </Alert>
          }

          <div id='highlighted_card'>
            <div className='signup_title'> {this.props.title} </div>

            <form onSubmit={this.authenticate}>
              <label>
                <div id='signin_input_fields'>
                  <div>
                    <input
                      className="default_roomble_input_box"
                      name="email"
                      type="text"
                      placeholder="Your .edu Email Address"
                      value={this.state.email}
                      onChange={this.userChange}
                      maxLength="50"
                    />
                  </div>
                  <div>
                    <input
                      className="default_roomble_input_box"
                      name="confirmEmail"
                      type="text"
                      placeholder="Confirm Email Address"
                      value={this.state.confirmEmail}
                      onChange={this.emailConfirmChange}
                      maxLength="50"
                    />
                  </div>
                  <div style={{ margin: ".5rem" }}></div>
                  <div>
                    <input
                      className="default_roomble_input_box"
                      name="password"
                      type="password"
                      placeholder="Password"
                      value={this.state.password}
                      onChange={this.passwordChange}
                      maxLength="50"
                    />
                  </div>
                </div>

                <div id="termsconditions_outer">
                  <div>
                    <Checkbox
                      className="termsconditions_checkbox"
                      name="termsconditions"
                      value={this.state.termsconditions}
                      onChange={this.termsconditionsChange}
                    />
                  </div>
                  <div id="termsconditions_text" className="signup_subtext">
                    I agree to the Roomble <a className='termsconditions_links' href="./Roomble Terms and Conditions.pdf">terms and conditions</a> and <a className='termsconditions_links' href="./Roomble Privacy Policy.pdf">privacy policy</a>
                  </div>
                </div>

                <div>
                  <input
                    className="default_roomble_button"
                    name="submit"
                    type="button"
                    disabled={!this.state.email || !this.state.password || !this.state.termsconditions
                      || this.state.email !== this.state.confirmEmail}
                    value={this.props.submitButton}
                    onClick={this.authenticate}
                  />
                </div>
              </label>
            </form>

          </div>

        </div>
      </div >
    );
  }
}

/* Send signup request */
async function Signup(email, password) {
  try {
    const urlParams = new URLSearchParams(window.location.search);
    const refer_token = urlParams.get('refer_token');
    var response = await axios.post(`${config.host}/SendVerificationEmail`, { email, password, refer_token });
    if (response.status === 200) {
      return true;
    }
  } catch (error) {
    console.log('Wrong email or password')
  }
  return false;
}

async function AddToEmailList(email) {
  try {
    var response = await axios.post(`${config.host}/AddToEmailList`, { email });
    if (response.status === 200) {
      return true;
    }
  } catch (error) {
    console.log(error);
  }
  return false;
}

export default Login;
