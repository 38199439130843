import axios from 'axios';
import { useEffect, useState } from 'react';
import Profile from '../Components/Profile';
import config from '../config.js';

/* Gets users own profile then renders with the profile component */

function UserProfilePage(props) {
  let [user, setUser] = useState({
    first_name: '',
    last_name: '',
    gender: '',
    age: '',
    socials: '',
    bio: '',
    building: '',
    room_type: ''
  });

  let [answers, setAnswers] = useState([]);
  let [displaySurvey, setDisplaySurvey] = useState(false);

  useEffect(() => {
    async function initializePage() {
      //signal done with signup process in case they somehow skip a step
      localStorage.removeItem('first signup')
      // initialize profile information
      var users = await getProfile();
      if (users == null) users = [];
      setUser(users);
      // get survey questions and get profile
      var oldAnswers = await getAnswers();
      if (oldAnswers == null) oldAnswers = [];
      setAnswers(oldAnswers);
      setDisplaySurvey(true);
      document.title = 'Profile';
    }
    initializePage();
  }, [])

  if (localStorage.getItem('roomies_auth_token') == null) {
    window.location.href = `${window.location.origin}/`;
    return;
  }

  return (
    <div>
      <div> {props.changePage("userprofile")} </div>
      {
        displaySurvey &&
        <div>
          <Profile
            user={user}
            answers={answers}
            displayFullSurvey={true}
            isProfilePage={true}
            isSwipePage={false}
          />
        </div>
      }
    </div>
  )
}

async function getProfile() {
  try {
    var res = await axios.get(`${config.host}/GetProfileById`, {
      headers: {
        roomies_auth_token: localStorage.getItem("roomies_auth_token"),
      }
    });
    return res.data;
  } catch (err) {
    console.log(err);
    return null;
  }
}

async function getAnswers() {
  try {
    var res = await axios.get(`${config.host}/GetSurveyResponse`, {
      headers: {
        roomies_auth_token: localStorage.getItem("roomies_auth_token"),
      }
    });
    return res.data;
  } catch (err) {
    console.log(err);
    return null;
  }
}

export default UserProfilePage
