import { React, useEffect, useState } from 'react';
import Checkbox from '../Components/Common/Checkbox/Checkbox.js';

/* Gets profile data to pass to bio component and render as a page */

function ChooseFiltersPage(props) {
    let [gradYears, setGradYears] = useState([])
    useEffect(() => {
        async function initializePage() {
            if (localStorage.getItem("filters_grad_years") == null) {
                setGradYears([true, true, true, true])
            }
            else {
                setGradYears(JSON.parse(localStorage.getItem("filters_grad_years")));
            }
        }
        initializePage()
    }, [])

    if (localStorage.getItem('roomies_auth_token') == null) {
        window.location.href = `${window.location.origin}/`;
        return;
    }

    function onGradYearChange(event, index) {
        let tempGradYears = [...gradYears];
        tempGradYears[index] = event.target.checked;
        setGradYears(tempGradYears);
    }

    function validate() {
        if (gradYears.includes(true) === false)
            return false;
        return true;
    }

    function submit() {
        localStorage.removeItem("filters_grad_years");
        localStorage.setItem("filters_grad_years", JSON.stringify(gradYears));
        window.location.href = `${window.location.origin}/matching`;
    }

    return (
        <div>
            <div>{props.changePage('choose_filters')}</div>
            <div id="highlighted_card">
                <div className='signup_title'>
                    Filter Roommates
                </div>
                <div className='signup_subtext'>
                    Filter the people in your feed
                </div>
                <div id="bio_spacer"></div>
                <div id="lifestyle_prefs_title">
                    Graduation Years
                </div>
                {/* TODO: ADD FLEXBOX SUCH THAT THERE'S 2 PER ROW*/}
                <div className='filter_gradyear_checkboxes_container'>
                    <div className='filter_gradyear_checkboxes_item'>
                        <Checkbox
                            name="2025"
                            value="2025"
                            checked={gradYears[0]}
                            onChange={(e) => { onGradYearChange(e, 0) }}
                            label="2025"
                        />
                    </div>
                    <div className='filter_gradyear_checkboxes_item'>
                        <Checkbox
                            name="2026"
                            value="2026"
                            checked={gradYears[1]}
                            onChange={(e) => { onGradYearChange(e, 1) }}
                            label="2026"
                        />
                    </div>
                    <div className='filter_gradyear_checkboxes_item'>
                        <Checkbox
                            name="2027"
                            value="2027"
                            checked={gradYears[2]}
                            onChange={(e) => { onGradYearChange(e, 2) }}
                            label="2027"
                        />
                    </div>
                    <div className='filter_gradyear_checkboxes_item'>
                        <Checkbox
                            name="2028"
                            value="2028"
                            checked={gradYears[3]}
                            onChange={(e) => { onGradYearChange(e, 3) }}
                            label="2028"
                        />
                    </div>
                </div>
                <button disabled={validate() === false} onClick={submit} id='lifestyle_submit_button' className='default_roomble_button' type="button">Submit</button>
            </div>
        </div>
    )
}

export default ChooseFiltersPage;