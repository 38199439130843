import React from 'react';

/* Renders the small user profile card card to appear on matches page for each match */

class MatchPreviewCard extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <div className="match_preview_box">
                    <div>
                        {this.props.images.length > 0 && <img src={this.props.images[0]} alt="profile loading" className="match_preview_photo" />}
                        {this.props.images.length === 0 && <img src="/defaultphoto.jpg" alt="profile loading" className="match_preview_photo" />}
                    </div>

                    <div className="match_preview_text">
                        <div className='match_preview_text_top'>
                            <div className="match_preview_name"> {this.props.first_name} {this.props.last_name} </div>
                            <div className="match_preview_subtext"> {this.props.age} {this.props.pronouns} </div>
                        </div>
                        <div className="match_preview_text_bottom">
                            <div className='match_preview_date'>
                                Matched {this.props.match_time}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default MatchPreviewCard;