import axios from 'axios';
import React, { useEffect, useState } from 'react';
import config from '../config.js';
import ToggleSwitch from '../Components/Common/ToggleSwitch/ToggleSwitch.js';
import SettingsBox from '../Components/UI/SettingsBox/SettingsBox.js';

/* Gets profile data to pass to bio component and render as a page */

function SettingsPage(props) {
  const [contentLoaded, setContentLoaded] = useState(false);
  const [EmailNotificationState, setEmailNotificationState] = useState({
    matchEmails: 0,
    swipeEmails: 0,
    deactivated_indef: 0
  });

  useEffect(() => {
    async function loadPageContent() {
      const emailNotificationPreferences = await GetEmailNotificationPreferences();
      setEmailNotificationState(emailNotificationPreferences);
      setContentLoaded(true);
    }
    loadPageContent();
  }, []);

  if (localStorage.getItem('roomies_auth_token') == null) {
    window.location.href = `${window.location.origin}/`;
    return null;
  }

  async function handleSubmit() {
    const result = await UpdateEmailNotificationPreferences(EmailNotificationState);
    if (result === false) {
      console.log("error");
    } else {
      window.location.href = `${window.location.origin}/userprofile`;
    }
  }

  function handleChange(e) {
    const { name, checked } = e.target;
    setEmailNotificationState(prevState => ({
      ...prevState,
      [name]: checked ? 0 : 1  // Inverting the logic to match the checked state
    }));
  }

  return (
    <div>
      <div>{props.changePage('settings')}</div>

      {contentLoaded && (
        <div>
          <div className='page_container'>
            <SettingsBox title="🔔 Email Preferences">
              <div className='settings_switch_container'>
                <ToggleSwitch
                  name="swipeEmails"
                  checked={EmailNotificationState.swipeEmails === 0}  // Checked if not opted out
                  onChange={handleChange}
                  label="Swipe Emails"
                />
              </div>

              <div className='settings_switch_container'>
                <ToggleSwitch
                  name="matchEmails"
                  checked={EmailNotificationState.matchEmails === 0}  // Checked if not opted out
                  onChange={handleChange}
                  label="Match Emails"
                />
              </div>
            </SettingsBox>

            <div className='informational_spacer'> </div>

            <button className="default_roomble_button" onClick={handleSubmit}>Submit</button>
          </div>
        </div>
      )}
    </div>
  );
}

async function GetEmailNotificationPreferences() {
  try {
    const res = await axios.get(`${config.host}/GetEmailNotificationPreference`, {
      headers: {
        roomies_auth_token: localStorage.getItem("roomies_auth_token"),
      }
    });
    const preferences = {
      matchEmails: 0,
      swipeEmails: 0,
      deactivated_indef: 0
    };
    res.data.forEach(pref => {
      preferences[pref.service_name] = pref.opted_out;
    });
    console.log("prefs from server", preferences);
    return preferences;
  } catch (err) {
    console.log(err);
    return null;
  }
}

async function UpdateEmailNotificationPreferences(preferences) {
  try {
    const requestBody = {
      "service_names": Object.keys(preferences),
      "preferences": Object.values(preferences)
    };
    console.log(requestBody);
    const res = await axios.post(`${config.host}/UpdateEmailNotificationPreference`, requestBody, {
      headers: {
        roomies_auth_token: localStorage.getItem("roomies_auth_token"),
      }
    });
    return res.data;
  } catch (err) {
    console.log(err);
    return false;
  }
}

export default SettingsPage;
