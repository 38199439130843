import { React, useEffect } from 'react';
import ReactGA from "react-ga4";

/* Renders page when user successfully validates email with the link */

function EmailValidatedPage(props) {
  useEffect(() => {
    async function updateAnalytics() {
      ReactGA.event({
        category: "Signup Process",
        action: "Email Validated Success",
        nonInteraction: false, // optional, true/false
      });
    }

    function redirectToBasicInfo() {
      // extract the token from the URL
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get('token');
      if (token != null) {
        // store the token into local storage
        localStorage.setItem('roomies_auth_token', token);

        // redirect to /basicinfo
        setTimeout(() => window.location.href = `${window.location.origin}/basicinfo`, 5000);
      }
    }

    updateAnalytics();
    document.title = 'Email Validated Success';
    var token = localStorage.getItem("refer_token");
    console.log(token)
    redirectToBasicInfo();

  }, [])

  return (
    <div>
      <div>{props.changePage('emailvalidated')}</div>
      <div id="highlighted_card">
        <div className='informational_header'> Sucesss! </div>
        <div className='informational_text'> Every user validates their .edu email to ensure you talk to real students. </div>
        <div className='informational_text'> We're redirecting you now to get started! </div>
      </div>
    </div>
  )
}

export default EmailValidatedPage;
