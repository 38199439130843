import React from 'react';
import { Link } from 'react-router-dom'
import like_button from '../Logo and Art Files/like_button.svg'
import dislike_button from '../Logo and Art Files/dislike_button.svg'
import classNames from "classnames";
import buildingMap from '../jsons/buildings.json'
import roomMap from '../jsons/roomTypes.json'
import questions from '../jsons/lifestyleSurveyQuestions.js'
import { isHousingInstitution } from '../utils';
import axios from 'axios'
import config from '../config.js'

let weights = ["filler for algorithm", "Very Important", "Somewhat Important", "Not Important"];
let questionResponses = [];

for (let i = 0; i < questions.length; i++) {
  questionResponses.push([questions[i].option1, questions[i].option2, questions[i].option3, questions[i].option4]);
}

/* Renders a full profile to be displayed on swipe page or users own profile page  */

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      questions,
      linkCopied: false,
      swipeRightAnimation: false,
      swipeLeftAnimation: false,
      swipeMatchAnimation: false,
    };

    this.renderContacts = this.renderContacts.bind(this);
    this.renderEditButton = this.renderEditButton.bind(this);
    this.swipeLeftCall = this.swipeLeftCall.bind(this);
    this.swipeRightCall = this.swipeRightCall.bind(this);
  }

  //function to conditionally render edit button (i.e. if we're on our own profile page)
  renderEditButton(page) {
    let redirectLoc = "/" + page;
    if (this.props.isProfilePage === true) {
      return (
        <span>
          <span>
            {
              (page === "bio") &&
              <Link to={redirectLoc} className={classNames({
                "user_profile_edit_button": true,
                "user_profile_edit_button_filled": (this.props.user.biography !== "[N/A]"),
                "user_profile_edit_button_unfilled": (this.props.user.biography === "[N/A]")
              })}>&nbsp; Edit </Link>
            }
            {
              (page === "photoupload") &&
              <div>
                <Link to={redirectLoc} className={classNames({
                  "user_profile_edit_button": true,
                  "user_profile_edit_button_filled": (this.props.user.images.length > 0),
                  "user_profile_edit_button_unfilled": (this.props.user.images.length === 0)
                })}>&nbsp; Edit </Link>
              </div>
            }
            {
              (page !== "bio" && page !== "photoupload") &&
              <Link to={redirectLoc} className={classNames({
                "user_profile_edit_button": true,
                "user_profile_edit_button_filled": true,
              })}>&nbsp; Edit </Link>

            }
          </span>
        </span>
      );
    }
  }

  //function to conditionally render the contacts (i.e. if there's been a match)
  renderContacts() {
    if (this.props.isSwipePage === false) {
      return (
        <div className='user_profile_box'>
          <div className="user_profile_title"> 🔗 Connect {this.renderEditButton("basicinfo")} </div>
          <div className="user_profile_subtext"> {this.props.user.email} </div>
        </div>
      );
    }
  }

  copyReferLinkToClipboard = async () => {
    console.log('clicked')
    var token = await getReferToken();
    const link = `${window.location.origin}/signup?refer_token=${token.data}`; // The link you want to copy
    navigator.clipboard.writeText(link).then(() => {
      this.setState({ linkCopied: true }, () => {
        setTimeout(() => this.setState({
          ...this.state,
          linkCopied: false
        }), 3000); // Hide message after 3 seconds
      });
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  }



  swipeRightCall() {
    if (this.props.isPendingMatch === false) {
      // Start animation
      this.setState({
        ...this.state,
        swipeRightAnimation: true,
      })
      // Rotate user
      setTimeout(() => {
        this.props.swipeRight();
        window.scrollTo({ top: 55, behavior: 'smooth' })
      }, 300);
      // Wait for animation to end then remove class
      setTimeout(() => {
        this.setState({
          ...this.state,
          swipeRightAnimation: false,
        })
      }, 500);
    }
    else if (this.props.isPendingMatch === true) {
      // Start animation
      window.scrollTo({ top: 55, behavior: 'smooth' })
      this.setState({
        ...this.state,
        swipeMatchAnimation: true,
      })
      // Rotate user
      setTimeout(() => {
        this.props.swipeRight();
      }, 300);
      // Wait for animation to end then remove class
      setTimeout(() => {
        this.setState({
          ...this.state,
          swipeMatchAnimation: false,
        })
      }, 1600);
    }
  }

  swipeLeftCall() {
    // Start animation
    this.setState({
      ...this.state,
      swipeLeftAnimation: true,
    })
    // Wait for animation to continue then rotate user
    setTimeout(() => {
      this.props.swipeLeft();
      window.scrollTo({ top: 55, behavior: 'smooth' })
    }, 300);
    // Wait for animation to end then remove class
    setTimeout(() => {
      this.setState({
        ...this.state,
        swipeLeftAnimation: false,
      })
    }, 500);
  }

  render() {
    var questionaire = [];
    var condensed_questionaire = [];
    /* Loop to convert backend survey data to text from question array */
    if (this.props.answers != null && this.props.answers.length > 0) {
      for (let i = 0; i < this.state.questions.length; i++) {
        let answerValue = '';
        let answerWeight = '';

        answerValue = this.props.answers[i].response_value;
        answerWeight = this.props.answers[i].preference_weight;

        questionaire.push(
          <div id="lifestyle_survey_display_box">
            <div id='lifestyle_survey_display_q'> {questions[i].question} </div>
            <div className='lifestyle_survey_display_text'> {questionResponses[i][answerValue]} </div>
            <div className='lifestyle_survey_display_text'> {weights[answerWeight]} </div>
          </div>
        );
      }
      if (this.props.hasOwnProperty("rankedAnswers")) {
        for (let i = 0; i < 4; i++) {
          let question_index = parseInt(this.props.rankedAnswers[i]['question_id'])
          let answerValue = this.props.answers[question_index].response_value;
          let answerWeight = this.props.answers[question_index].preference_weight;


          condensed_questionaire.push(
            <div id="lifestyle_survey_display_box">
              <div id='lifestyle_survey_display_q'> {questions[question_index].question} </div>
              <div className='lifestyle_survey_display_text'> {questionResponses[question_index][answerValue]} </div>
              <div className='lifestyle_survey_display_text'> {weights[answerWeight]} </div>
            </div>
          )
        }
      }
    }

    // do calculations for progress bar
    let progress_bar_fraction = 1;
    let next_profile_step = ""
    if (this.props.user.images.length === 0) {
      progress_bar_fraction -= .25;
      next_profile_step = "Upload a photo."
    }
    if (this.props.user.biography === "[N/A]") {
      progress_bar_fraction -= .25;
      next_profile_step = "Update your bio."
    }
    const progress_bar_style = {
      width: `${progress_bar_fraction * 100}%`
    }

    return (
      <div>
        <div id="swipe_animation_wrapper">
          <div className={classNames({
            "user_profile_image_swipe_right": (this.state.swipeRightAnimation),
            "user_profile_image_swipe_left": (this.state.swipeLeftAnimation),
            "user_profile_image_swipe_match": (this.state.swipeMatchAnimation),
          })}> {/*Swipe Animation*/}</div>

          <div className={classNames({
            "user_profile_match_text": (this.state.swipeMatchAnimation),
            "user_profile_match_text_hidden": (this.state.swipeMatchAnimation === false)
          })}>
            It's a Match!
          </div>
        </div>
        <div id="user_profile_page_box">

          {this.props.isProfilePage === true &&
            <div className='profile_two_column'>
              <Link to='/settings'>
                <img className="settings_button_container hover_grow_shadow" src='/settingsIcon.svg' alt="settings" />
              </Link>

              <div className='refer_friend_button hover_grow_shadow' onClick={this.copyReferLinkToClipboard}>
                {!this.state.linkCopied && "Refer a Friend"}
                {this.state.linkCopied && "Refer Link Copied!"}
              </div>
            </div>
          }


          {this.props.isProfilePage === true && progress_bar_fraction < 1 &&
            <div className="user_profile_box">
              <div className='progress_bar_title'>
                <span className='user_profile_title'> 📈 Profile Progress </span>
                <span className='user_profile_subtext'> {progress_bar_fraction * 100}% Done </span>
              </div>
              <div className='progress_bar_background'>
                <div className='progress_bar_completion' style={progress_bar_style}> </div>
              </div>

              <div className='user_profile_subtext'>
                Next up: {next_profile_step}
              </div>
            </div>
          }

          <div>
            {/* Render the back button if a user is viewing a potential match */}
            {this.props.isMatchesPage &&
              <div>
                <button id="user_profile_back_button" onClick={() => { this.props.backButton() }}> ←back </button>
              </div>}
          </div>

          <div>
            <img
              src={this.props.user.images.length ? this.props.user.images[0] : "/defaultphoto.jpg"}
              alt="profile" className="user_profile_image_box" />
          </div>

          {this.props.isProfilePage === true &&
            <div id="user_profile_photo_upload_edit_button">
              {this.renderEditButton("photoupload")}
            </div>
          }

          {(this.props.isSwipePage || this.props.isMatchesPage) &&
            <div id="swipe_page_profile_space"> </div>
          }

          <div className='user_profile_box'>
            <div id="user_profile_color_overlay">
              <div id="user_profile_name"> {this.props.user.first_name}&nbsp;{this.props.user.last_name} </div>
            </div>

            <div id="user_profile_pronouns_age"> {this.props.user.age} {this.props.user.pronouns} {this.renderEditButton("basicinfo")}</div>
          </div>

          <div>
            {this.renderContacts() /* Render user's contact info if not swiping */}
          </div>

          {((this.props.user.biography !== "[N/A]") || (this.props.isProfilePage)) &&
            <div className='user_profile_box'>
              <div className="user_profile_title"> ✍️ About {this.renderEditButton("bio")}</div>
              <div className="user_profile_subtext">
                {(this.props.user.biography === "[N/A]") && "Write a bio to let others know what you're about. Your bio won't show on your profile until you do."}
                {(this.props.user.biography !== "[N/A]") && this.props.user.biography}
              </div>
            </div>
          }

          {(this.props.isSwipePage === true) &&
            <div id="swipe_button_box">
              <div id="swipe_box">
                <div className="swipe_buttons">
                  <button className='like_button_wrapper' onClick={() => this.swipeRightCall()}>
                    <img src={like_button} alt="like button" id="like_button" />
                  </button>


                  <button className='dislike_button_wrapper' onClick={() => this.swipeLeftCall()}>
                    <img src={dislike_button} alt="like button" id="dislike_button" />
                  </button>
                </div>
              </div>
            </div>
          }

          {isHousingInstitution(this.props.user.institution) && (
            ((this.props.isSwipePage || this.props.isMatchesPage) && this.props.user.building + '' !== "-1" && this.props.user.room_type + '' !== "-1" &&
              this.props.user.building + '' !== "-2" && this.props.user.room_type + '' !== "-2") || // If on swipe or matches page only show if the person has declared housing
            (this.props.isProfilePage)) &&
            <div className='user_profile_box'>
              <div className="user_profile_title"> 🏠 On Campus Housing {this.renderEditButton("basicinfo")}</div>
              <div className="user_profile_subtext">
                <div>
                  Building: {buildingMap[this.props.user.institution][this.props.user.building + '']}
                </div>
                <div>
                  Room Type: {roomMap[this.props.user.institution][this.props.user.room_type + '']}
                </div>
              </div>
            </div>
          }

          {this.props.displayFullSurvey === false && this.props.hasOwnProperty("rankedAnswers") &&
            <div div className='user_profile_box'>
              <div className="user_profile_title"> 🤝 Closest Commonalities {this.renderEditButton("lifestylesurvey")}</div>
              {condensed_questionaire}
              <div>
                <button onClick={() => { this.props.swapSurveyDisplay() }} id="survey_switch_button"> View Full Survey </button>
              </div>
            </div>
          }

          {this.props.displayFullSurvey === true &&
            <div className='user_profile_box'>
              <div className="user_profile_title"> ⏳ Prefs & Past Times {this.renderEditButton("lifestylesurvey")}</div>
              {questionaire}
              {this.props.isProfilePage === false && this.props.hasOwnProperty("rankedAnswers") &&
                <div div >
                  <button onClick={() => { this.props.swapSurveyDisplay() }} id="survey_switch_button"> View Condensed Survey </button>
                </div>}
            </div>
          }
        </div>
      </div>
    );
  }
}

async function getReferToken() {
  try {
    var res = await axios.get(`${config.host}/GenerateReferToken`, {
      headers: {
        roomies_auth_token: localStorage.getItem("roomies_auth_token"),
      },
    });
    console.log("TOKEN")
    console.log(res)
    return res;
  } catch (err) {
    console.log("Failed to generate token");
    return null;
  }
}

export default Profile;
