import React from 'react'
import axios from 'axios'
import ReactGA from "react-ga4";
import config from '../config.js'

/* Renders the edit user bio form component */

class Bio extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bio: "",
    };

    /* If the component is passed an old bio use it as the default value */
    if (props.hasOwnProperty("old_bio") && props.old_bio !== "[N/A]" && props.old_bio != null) {
      this.state = {
        ...this.state,
        bio: props.old_bio,
      }
    }

    this.handleTextChange = this.handleTextChange.bind(this);
    this.handleBioSubmit = this.handleBioSubmit.bind(this);
  }

  handleTextChange(e) {
    e.preventDefault()
    const target = e.target;
    const value = target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  /* Submits bio to backend and moves user to next page */
  async handleBioSubmit() {
    if (this.state.bio === "")
      this.state.bio = "[N/A]"
    var result = await updateBio(this.state.bio);
    if (result === false) {
      console.log("error");
    }
    else {
      console.log("success");
      ReactGA.event({
        category: "Update Profile",
        action: "Bio Update",
        nonInteraction: false,
      });
    }
    if (localStorage.getItem('first signup') === "true")
      window.location.href = `${window.location.origin}/photoupload`;
    else
      window.location.href = `${window.location.origin}/userprofile`;
  }

  render() {
    return (
      <div>
        {localStorage.getItem("first signup") === "true" &&
          <div>
            <div id="highlighted_card">
              <div className='signup_title'>
                Almost there!
              </div>
              <div className='signup_subtext'>
                You can always change this info later.
              </div>
            </div>
            <div id="bio_spacer"></div>
          </div>
        }
        <div id="highlighted_card">
          <div className="signup_title"> In Your Words... ✍ ️ </div>
          <div className="signup_subtext"> So other Roomblers get your vibe. </div>
          <form>
            <label>
              <div>
                <textarea
                  id="extended_bio_input_box"
                  className="default_roomble_input_box"
                  name="bio"
                  placeholder="Share your thoughts and what you're about..."
                  value={this.state.bio}
                  onChange={this.handleTextChange}
                  maxLength={300}>
                </textarea>
                <div> {this.state.bio.length} / 300 characters </div>
              </div>

              <div id='bio_spacer'></div>

              <div>
                <input
                  className="default_roomble_button"
                  name="submit"
                  type="button"
                  // disabled={this.state.bio === ""}
                  value="Next"
                  onClick={this.handleBioSubmit}
                />
              </div>

              <div>
                <input
                  className="default_roomble_button"
                  name="submit"
                  type="button"
                  value="Skip for now"
                  disabled={this.state.bio !== ""}
                  onClick={this.handleBioSubmit}
                />
              </div>
            </label>
          </form>
        </div>
      </div >
    );
  }
}

async function updateBio(bio) {
  try {
    var res = await axios.post(`${config.host}/UpdateProfilePost`, {"bio": bio}, {
      headers: {
        roomies_auth_token: localStorage.getItem("roomies_auth_token"),
      },
    });
    return true;
  } catch (err) {
    console.log("failed to upload bio");
    return false;
  }
}

export default Bio;
