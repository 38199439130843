let config = {
    host: "https://roomble.net", // Live server host
    // host: "http://localhost:1337", // Local host for testing

    liveSchools: [ // List of schools where the app is live
    {
      name: "UCLA",
      emailExtension: "@g.ucla.edu",
    },
    {
      name: "UCLA",
      emailExtension: "@ucla.edu",
    },
    {
      name: "USC",
      emailExtension: "@usc.edu",
    },
    {
        name: "UC Berkeley",
        emailExtension: "@berkeley.edu",
    },
    // add more schools as necessary
  ]
}

module.exports = config;
