/* Returns random relatable sentence from below */

const randomRelatable = function randomText() {
    const ArrayList = [
        "better than opening a new uncrustable",
        "like being the guy who won the danimal’s raffle",
        "like a getting an otterpop in the summer",
        "better than a snow day call late at night",
        "like talking on DS pictochat at a sleepover",
        "like finding out you have a field trip to legoland",
        "better than beating the lava level of lego star wars",
        "better than getting school computer time on coolmathgames",
        "like Ferb pulling Vanessa",
        "better than Fa la la lidays on Disney",
        "like getting to go on an adventure with the Backyardigans",
        "better than going to gamestop with your parents",
        "like finding out everyone’s sitting under the parachute in gym",
        "like watching your friend play clash of clans on their ipod touch",
        "like the feeling when “tonight’s gonna be a good night” starts playing on the fourth of July",
        "better than the smell of opening a new box of Play dough",
        "like finding out schoolhouse rock Bill on Capitol hill became a law",
        "like finding out CollegeBoard went bankrupt",
        "like the air on the morning before a field trip",
        "better than playing wii sports at your friends house"
    ];

    const randomIndex = Math.floor(Math.random() * (ArrayList.length));
    return ArrayList[randomIndex];
}

export default randomRelatable;