import React from 'react';
import { Route, Switch } from "react-router-dom";
import TopNavbar from "./Components/TopNavbar.js";
import BottomNavbar from './Components/BottomNavbar.js';
import LoginPage from "./Pages/LoginPage";
import MatchesPage from "./Pages/MatchesPage";
import EntrancePage from './Pages/EntrancePage.js';
import PhotoUpload from './Pages/PhotoUploadPage.js';
import BioPage from './Pages/BioPage.js';
import BasicInfoPage from './Pages/BasicInfoPage.js';
import "bootstrap/dist/css/bootstrap.min.css";
import LifestyleSurveyPage from './Pages/LifestyleSurveyPage.js';
import UserProfilePage from './Pages/UserProfilePage.js';
import ValidateEmailPage from './Pages/ValidateEmailPage.js';
import EmailValidatedPage from './Pages/EmailValidatedPage.js';
import EmailValidatedFailPage from './Pages/EmailValidatedFail.js';
import UpcomingSchoolPage from './Pages/UpcomingSchoolPage.js';
import WebsiteDownPage from './Pages/WebsiteDownPage.js';
import SwipePage from './Pages/SwipePage';
import LandingPage from './Pages/LandingPage';
import ChooseFiltersPage from './Pages/ChooseFiltersPage.js';
import SettingsPage from './Pages/SettingsPage.js';
import NotFoundPage from './Pages/NotFoundPage';
import TopHeader from './Components/TopHeader.js';
import Footer from './Components/Footer.js';
import classNames from "classnames";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: '',
      userid: -1,
    }
  };

  currPage = (page) => {
    let newPage = page;
    if (newPage !== this.state.page) {
      this.setState({
        page: page,
      })
      console.log(page)
    }
  }

  changeUser = (userid) => {
    let newUser = userid;
    if (newUser !== this.state.userid) {
      this.setState({
        userid: userid,
      })
      console.log('user id changed')
    }
  }

  displayTopNavbar() {
    if (this.state.page !== 'login' && this.state.page !== 'entrance' && this.state.page !== 'emailvalidated' && this.state.page !== 'validateemail')
      return true;
    return false;
  }

  displayTopItem() {
    if (this.state.page === 'basicinfo' || this.state.page === 'lifestylesurvey' || this.state.page === 'bio' || this.state.page === 'photoupload' || this.state.page === 'userprofile' || this.state.page === 'swipe' || this.state.page === 'matches' || this.state.page === "choose_filters" || this.state.page === "settings")
      return (<TopNavbar logOut={this.changeUser.bind(this)} />)
    else if (this.state.page === 'login' || this.state.page === 'entrance' || this.state.page === 'emailvalidated' || this.state.page === "validateemail" || this.state.page === 'notfound' || this.state.page === 'websitedown' || this.state.page === 'upcomingschool')
      return (<TopHeader />)
    else
      return;
  }

  displayBottomItem() {
    if (this.state.page === "userprofile" || this.state.page === "matches" || this.state.page === "swipe")
      return <BottomNavbar page={this.state.page} />
    else if (this.state.page === 'login' || this.state.page === 'entrance' || this.state.page === 'emailvalidated' || this.state.page === "validateemail" || this.state.page === 'notfound' || this.state.page === 'websitedown')
      return <Footer absolute={true} />
    else if (this.state.page === "upcomingschool")
      return <Footer absolute={false} />
  }

  render() {
    return (
      <div className={classNames({
        "App_color_background": (this.state.page === "matches" || this.state.page === "userprofile" || this.state.page === 'notfound' || this.state.page === 'websitedown'
          || this.state.page === "swipe" || this.state.page === "basicinfo" || this.state.page === "lifestylesurvey" || this.state.page === "bio" || this.state.page === "photoupload"
          || this.state.page === "emailvalidated" || this.state.page === "validateemail" || this.state.page === "choose_filters" || this.state.page === "upcomingschool" || this.state.page === "settings"),
        "App_swirl_background": (this.state.page === "login" || this.state.page === 'entrance'),
      })}>
        {this.displayTopItem()}
        <Switch>
          <Route exact path="/matches" render={() => (<MatchesPage changePage={this.currPage.bind(this)} user={this.state.userid} />)} />
          <Route exact path="/login" render={() => (<LoginPage changePage={this.currPage.bind(this)} user={this.state.userid} />)} />
          <Route exact path="/photoupload" render={() => (<PhotoUpload changePage={this.currPage.bind(this)} user={this.state.userid} />)} />
          <Route exact path="/bio" render={() => (<BioPage changePage={this.currPage.bind(this)} user={this.state.userid} />)} />
          <Route exact path="/basicinfo" render={() => (<BasicInfoPage changePage={this.currPage.bind(this)} user={this.state.userid} />)} />
          <Route exact path="/lifestylesurvey" render={() => (<LifestyleSurveyPage changePage={this.currPage.bind(this)} user={this.state.userid} />)} />
          <Route exact path="/filter" render={() => (<ChooseFiltersPage changePage={this.currPage.bind(this)} user={this.state.userid} />)} />
          <Route exact path="/userprofile" render={() => (<UserProfilePage changePage={this.currPage.bind(this)} user={this.state.userid} />)} />
          <Route exact path="/settings" render={() => (<SettingsPage changePage={this.currPage.bind(this)} user={this.state.userid} />)} />
          <Route exact path="/validateemail" render={() => (<ValidateEmailPage changePage={this.currPage.bind(this)} user={this.state.userid} />)} />
          <Route exact path="/emailvalidated" render={() => (<EmailValidatedPage changePage={this.currPage.bind(this)} user={this.state.userid} />)} />
          <Route exact path="/emailvalidatedfail" render={() => (<EmailValidatedFailPage changePage={this.currPage.bind(this)} user={this.state.userid} />)} />
          <Route exact path="/upcomingschool" render={() => (<UpcomingSchoolPage changePage={this.currPage.bind(this)} user={this.state.userid} />)} />
          <Route exact path="/matching" render={() => (<SwipePage changePage={this.currPage.bind(this)} user={this.state.userid} />)} />
          <Route exact path="/signup" render={() => (<EntrancePage changePage={this.currPage.bind(this)} user={this.state.userid} />)} />
          <Route exact path="/" render={() => (<LandingPage changePage={this.currPage.bind(this)} user={this.state.userid}/>)} />
          <Route exact path="*" render={() => (<NotFoundPage changePage={this.currPage.bind(this)} user={this.state.userid} />)} />
          {/* <Route exact path="/*" render={() => (<WebsiteDownPage changePage={this.currPage.bind(this)} user={this.state.userid} />)} /> */}
        </Switch>
        {this.displayBottomItem()}
      </div>
    );
  }
}

export default App;
