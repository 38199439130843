import { React, useEffect, useState } from 'react';
import BurgerMenu from '../Components/BurgerMenu';
import MailchimpForm from '../Components/MailchimpForm';
import randomRelatable from '../Components/randomRelatable';
import RoombleLogo from '../Logo and Art Files/logo.svg'
import config from '../config.js'

/* Renders landing page */

function LandingPage(props) {

  let [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    document.title = 'Roomble';
    document.documentElement.style.backgroundColor = "white"; //changes html background color to white

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [])

  let [burgerOpen, setBurgerOpen] = useState(false);

  const handleBurgerClick = () => {
    setBurgerOpen(!burgerOpen);
    console.log(burgerOpen)
  };

  return (
    <div id="landing_page_outer" > {props.changePage('landing')}
      <div>
        <div> {/* Top Item */}
          <div className='landing_page_navbar'>
            <div className='landing_page_burger_menu'> { /* Only shows on mobile */}
              <BurgerMenu
                open={burgerOpen}
                handleClick={handleBurgerClick}
              />
            </div>
            <div className='landing_page_navbar_text_logo'> Roomble </div>
            <div className='landing_page_navbar_links'>
              <div> <a className='landing_page_navbar_link' href={`${window.location.origin}/login`}> Login </a> </div> { /* 3 links only show on desktop */}
              <div> <a className='landing_page_navbar_link' href='https://linktr.ee/roomble'> Learn More </a> </div>
              <div>  <a className='landing_page_navbar_link' href='mailto:roomble.customer.service@gmail.com'> Help </a> </div>
              <div>
                <img src={RoombleLogo} alt="Roomble Logo" className="landing_page_navbar_image_logo" />
              </div>
            </div>
          </div>
        </div>

        <div> {/* Middle Item 1 */}
          <div className='landing_page_upper_body'>
            <div className='landing_page_text_container'>
              <div className='landing_page_text_box_wrapper'>
                <div>
                  <div className='landing_page_title_text'>
                    Your college {windowWidth < 1100 && <br />} <span className='landing_page_title_text_highlight'> roommate </span> <br /> is only<span className='landing_page_title_text_highlight'> a {windowWidth < 1100 && <br />} swipe away </span>
                  </div>
                  <div>
                    <div className='landing_page_mailchimp_form'>
                      <MailchimpForm />
                    </div>
                  </div>
                  <div className='landing_page_upper_body_footer'>
                    Made by students for students
                  </div>
                </div>
              </div>
            </div>

            <div className='landing_page_desktop_image_container'>
              <div className='landing_page_image_box'>
                <img src="./phone_mockup.svg" alt="phone mockup" className='landing_page_image' />
              </div>
            </div>
          </div>
        </div>

        <div> { /* Middle Item 2 */}
          <div className='landing_page_lower_body'>
            <div className='landing_page_image_box'>
              <img src="./phone_mockup.svg" alt="phone mockup" className='landing_page_image' />
            </div>
            <div className='landing_page_lower_body_text'>
              Get ready to Roomble 🎉
            </div>
          </div>
        </div>

        <div className='landing_page_testimonials_wrapper'> {/* Testimonials */}
          <div>
            <div className='landing_page_testimonials_textbox landing_page_gray_textbox'>
              <span className='landing_page_title_text'> <div className='landing_page_testimonials_title'> Suite {windowWidth < 575 && <br />} Successes {windowWidth > 420 && "🎉"}  </div></span>
              <div className='landing_page_testimonials_container'>

                <div className='landing_page_testimonials_polaroid_container'> {/* Image */}
                  <img src="/Elle_Ashlyn_Photo.png" alt="Elle and Ashlyn at Game" className='landing_page_testimonials_photo' />
                  <div className='landing_page_testimonials_names'>
                    <div>Ashlyn & Elle</div>
                    <div className='landing_page_testimonials_names_subtext'>UCLA 2027</div>
                  </div>
                </div>

                <div> {/* Text */}
                    <div className='landing_page_testimonials_text'>
                      <div>
                        <div>
                          "Ashlyn is my <span className='landing_page_testimonials_text_highlight'>perfect match</span> as a friend and roommate. We eat every meal together and have inside jokes. Your questions and matching process worked perfectly for me. <span className='landing_page_testimonials_text_highlight'>I cannot thank Roomble enough</span> for finding my best friend!”
                        </div>
                        <div className='landing_page_testimonials_who_from'>
                          - Elle | 1st year Business Economics Major
                        </div>
                    </div>
                    
                    <div className='landing_page_testimonials_text_divider'>

                    </div>
                    
                      <div>
                        <div>
                          “Roomble has given me the <span className='landing_page_testimonials_text_highlight'>best roommate experience I could’ve imagined. </span> I can always count on Elle to be there for me. Roomble not only led me to a college roommate, but a lifelong best friend!”
                        </div>
                        <div className='landing_page_testimonials_who_from'>
                          - Ashlyn | 1st year Math Economics Major
                        </div>
                      </div>
                    </div>

                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='landing_page_features_schools_wrapper'>
          <div className='landing_page_gray_textbox'> { /* As Featured In */}
            <div className='landing_page_gray_textbox_header'>
              As Featured In:
            </div>
            <div className='landing_page_gray_box_logos_wrapper'>
              <img src="/DailyBruin.svg" alt="Daily Bruin Logo" className='landing_page_gray_box_logo' style={{ height: "1.75em" }} />
            </div>
          </div>


          <div className='landing_page_gray_textbox'> { /* Live Schools */}
            <div className='landing_page_gray_textbox_header'>
              Roomble is live for:
            </div>
            <div className='landing_page_gray_box_logos_wrapper'>
              <div>
                <img src="/UCLA.svg" alt="Daily Bruin Logo" className='landing_page_gray_box_logo' style={{ height: "1.75em" }} />
              </div>
              <div>
                <img src="/USC.svg" alt="Daily Bruin Logo" className='landing_page_gray_box_logo' style={{ height: "1.75em" }} />
              </div>
              <div>
                <img src="/Berkeley.svg" alt="Daily Bruin Logo" className='landing_page_gray_box_logo' style={{ height: "2.25em" }} />
              </div>
            </div>
          </div>

        </div>

        <div> {/* Bottom Item */}
          <div className='landing_page_footer'>
            <div className='landing_page_footer_highlight'>
              Made by students for students
            </div>
            <div>
              Roomble is...{randomRelatable()}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LandingPage;
