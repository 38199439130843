import React from 'react'
import RoombleLogo from '../Logo and Art Files/logo.svg'

/* Header for pages where user isn't logged in */

class TopHeader extends React.Component {
    render() {
        return (
            <div>
                <div id="top_header">
                    <img src={RoombleLogo} alt="Roomble Logo" id="header_logo" />
                    <div id="top_header_text">
                        Roomble
                    </div>
                </div>
            </div>
        );
    }
}

export default TopHeader;
