import { React, useEffect } from 'react';

/* Renders page for any invalid urls */

function NotFoundPage(props) {
  useEffect(() => {
    document.title = 'Roomble';
  }, [])

  function notfound_button_click() {
    window.location.href = `${window.location.origin}/`;
  }

  return (
    <div> {props.changePage('notfound')}
      <div id='highlighted_card'>
        <div className='informational_header'> Hmmm 🤔 </div>
        <div className='informational_text'> Seems you found a page that doesn't exist.</div>
        <div className='informational_text'> Click below to navigate back home. </div>
        <div className='informational_spacer'> </div>
        <div> <button onClick={notfound_button_click} className="default_roomble_button"> Home </button></div>
      </div>
    </div>
  )
}

export default NotFoundPage;