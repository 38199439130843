import MatchPreviewCard from '../Components/MatchPreviewCard';
import axios from 'axios';
import { useEffect, useState } from "react";
import Profile from '../Components/Profile';
import ReactGA from "react-ga4";
import config from '../config.js';

/* Renders matches page */

// Collect list of matches from database
function MatchesPage(props) {
  // Define initial states
  const [profiles, setProfiles] = useState([])
  const [matchTimes, setMatchTimes] = useState([])
  const [surveys, setSurveys] = useState([]);
  const [surveyRanking, setSurveyRanking] = useState([]);
  const [displayContent, setDisplayContent] = useState(false);
  let [fullUser, setFullUser] = useState(null);
  let [displayFullSurvey, setDisplayFullSurvey] = useState(false);

  // Define async function to grab list of matches and profiles
  useEffect(async () => {
    let matches_info = await GetMatches();
    let new_matches = matches_info[0]
    let new_matches_times = matches_info[1];
    let new_profiles = await GetProfiles(new_matches)
    let new_surveys = await getSurveyAnswers(new_matches)
    let ranked_surveys = []
    for (let i = 0; i < new_matches.length; i++) {
      let single_survey_rank = await getOrderedSurveyAnswers(new_matches[i])
      ranked_surveys.push(single_survey_rank)
    }

    setMatchTimes(new_matches_times)
    setProfiles(new_profiles)
    setSurveys(new_surveys)
    setSurveyRanking(ranked_surveys);
    console.log(ranked_surveys)
    setDisplayContent(true);
    document.title = 'Mutuals';
  }, []);

  if (localStorage.getItem('roomies_auth_token') == null) {
    window.location.href = `${window.location.origin}/`;
    return;
  }

  function closeFullProfile() {
    setFullUser(null)
    setDisplayFullSurvey(false)
  }

  function swapSurveyDisplay() {
    setDisplayFullSurvey(!displayFullSurvey)
  }

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" }
    return new Date(dateString).toLocaleDateString(undefined, options)
  }


  /* Loops over matches and renders cards and places them in array */
  var cards = [];
  for (let i = 0; i < profiles.length; i++) {
    let profile = profiles[i]
    let survey = surveys[i]
    let ranked_survey = surveyRanking[i]
    cards.push((
      <button id="match_card_button" onClick={() => {
        setFullUser([profile, survey, ranked_survey]);
        ReactGA.event({
          category: "Matches",
          action: "View Match",
          nonInteraction: false,
        });
      }}>
        <MatchPreviewCard
          first_name={profile["first_name"]}
          last_name={profile["last_name"]}
          pronouns={profile["pronouns"]}
          age={profile["age"]}
          email={profile["email"]}
          bio={profile["biography"]}
          images={profile["images"]}
          match_time={formatDate(matchTimes[i])}
        />
      </button>))
  }

  return (
    <div id="matches_page_outer">
      <div>{props.changePage('matches')}</div>
      {(displayContent) &&
        <div>
          {profiles.length == 0 &&
            <div id="no_matches_page">
              <div id="card">
                <div className='card_header'>
                  Mutuals Page
                </div>
                <div className="card_text">
                  Get out there and <br /> get some mutuals! :)
                  <br /> <br />
                  Other Roomblers' contact info <br />will appear after you've matched
                </div>
              </div>
            </div>}
        </div>
      }
      {
        displayContent && profiles.length > 0 &&
        <div>
          {!fullUser &&
            <div id="matches">
              <div className="matches_title_box">
                <div id="card">
                  <div className='card_header'>
                    Mutuals Page
                  </div>
                  <div className="card_text">
                    Click on each match to see<br /> their profile and contact info!
                  </div>
                </div>
              </div>
              {cards}
            </div>
          }
          {fullUser &&
            <div>
              <Profile
                user={fullUser[0]}
                answers={fullUser[1]}
                rankedAnswers={fullUser[2]}
                swapSurveyDisplay={() => swapSurveyDisplay()}
                displayFullSurvey={displayFullSurvey}
                backButton={() => closeFullProfile()}
                isSwipePage={false}
                isProfilePage={false}
                isMatchesPage={true}
              />
            </div>
          }
        </div>
      }
    </div >
  )
}

async function GetMatches() {
  try {
    var res = await axios.get(`${config.host}/GetConfirmedMatches`, {
      headers: {
        roomies_auth_token: localStorage.getItem("roomies_auth_token")
      }
    });
    return (res.data)
  } catch (err) {
    console.log(err);
    return false;
  }
}

async function GetProfiles(matches_list) {
  try {
    var res = await axios.post(`${config.host}/GetProfiles`, { "user_ids": matches_list }, {
      headers: {
        roomies_auth_token: localStorage.getItem("roomies_auth_token")
      }
    });
    return (res.data)
  } catch (err) {
    console.log(err);
    return false;
  }
}

async function getSurveyAnswers(matches_list) {
  try {
    let res = await axios.post(`${config.host}/GetSurveyResponses`, { "user_ids": matches_list }, {
      headers: {
        roomies_auth_token: localStorage.getItem("roomies_auth_token"),
      },
    });
    return res.data;
  } catch (err) {
    console.log(err);
    return null;
  }
}

async function getOrderedSurveyAnswers(matched_user) {
  try {
    let res = await axios.get(`${config.host}/GetCompatibleSurveyAnswers?matched_user=${matched_user}`, {
      headers: {
        roomies_auth_token: localStorage.getItem("roomies_auth_token"),
      },
    });
    return res.data;
  } catch (err) {
    console.log(err);
    return null;
  }
}

export default MatchesPage;
