import React from 'react';
import styles from "./SettingsBox.module.css";

const SettingsBox = ({ title, children }) => {
    const childCount = React.Children.count(children);

    return (
        <div>
            <div className={styles.title}>
                {title}
            </div>
            <div className={styles.container}>
                {React.Children.map(children, (child, index) => {
                    let classNames = [styles.box]; // Initialize with 'box' style

                    // Always add divider except for the last child
                    if (index !== childCount - 1) {
                        classNames.push(styles.divider);
                    }

                    if (childCount === 1) { // If only one child, apply rounded corners to both top and bottom
                        classNames.push(styles.topRounded, styles.bottomRounded);
                    } else if (index === 0) { // If first child, apply rounded corners to top
                        classNames.push(styles.topRounded);
                    } else if (index === childCount - 1) { // If last child, apply rounded corners to bottom
                        classNames.push(styles.bottomRounded);
                    }

                    // Wrap the child in a div to apply Flexbox styling correctly
                    return <div className={classNames.join(' ')}>{child}</div>;
                })}
            </div>
        </div >
    );
};

export default SettingsBox;
