import axios from 'axios';
import { React, useEffect, useState } from 'react';
import Bio from '../Components/Bio';
import config from '../config.js';

/* Gets profile data to pass to bio component and render as a page */

function BioPage(props) {
  const [bio, setBio] = useState("")
  let [displayBio, setDisplayBio] = useState(false);

  useEffect(async () => {
    // get survey questions and get profile
    var oldBio = await getProfile();
    if (oldBio == null) oldBio = null;
    console.log(oldBio);
    setBio(oldBio.biography);
    setDisplayBio(true);
    document.title = 'Bio';
  }, [])

  if (localStorage.getItem('roomies_auth_token') == null) {
    window.location.href = `${window.location.origin}/`;
    return;
  }

  return (
    <div>
      <div>{props.changePage('bio')}</div>

      {displayBio && <Bio old_bio={bio} />}
    </div>
  )
}
async function getProfile() {
  try {
    var res = await axios.get(`${config.host}/GetProfileById`, {
      headers: {
        roomies_auth_token: localStorage.getItem("roomies_auth_token"),
      }
    });
    return res.data;
  } catch (err) {
    console.log(err);
    return null;
  }
}

export default BioPage;