import React from 'react';
import ReactDOM from 'react-dom';
import './css/index.css';
import './css/matches_page.css';
import './css/signup_and_login.css'
import './css/photo_upload_page.css'
import './css/bio_page.css'
import './css/basic_info_page.css'
import './css/lifestyle_survey_page.css'
import './css/top_bottom_navbar.css'
import './css/user_profile_page.css'
import './css/swipe_page.css'
import './css/landing_page.css'
import './css/burger_menu.css'
import './css/universal.css'
import './css/upcoming_school_page.css'
import './css/settings_page.css'
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import ReactGA from "react-ga4";

ReactGA.initialize("G-TZXFSKWJV7");
ReactDOM.render(
  <BrowserRouter>
    <div>
      <App />
    </div>
  </BrowserRouter>,
  document.getElementById('root')
);
const SendAnalytics = () => {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(SendAnalytics);
