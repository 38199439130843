import { React, useEffect } from 'react';
import Login from '../Components/Login';
import config from '../config.js'
/* Renders login page */

function LoginPage(props) {
  useEffect(() => {
    document.title = 'Login';
  }, [])

  return (
    <div id="login_page">
      <div id='sign_in_page_arrangement'>
        <div>{props.changePage('login')}</div>

        <div id="signup_login_despacer"></div>

        <Login
          title="Welcome Back!"
          submitButton="Login" />

        <div id='signin_switch_pages_text'>
          Never Roombled Before?
          <a id='signin_help_link' href={`${window.location.origin}/signup`}> Sign Up </a>
        </div>
        <div id='signin_help_text'>
          Questions or trouble?{' '}
          <a id='signin_help_link' href="mailto:roomble.customer.service@gmail.com"> Email Us </a>
        </div>
      </div>
    </div>
  )
}

export default LoginPage;