import React from 'react';
import { Alert } from 'react-bootstrap'
import axios from 'axios';
import config from '../config.js';

/* Renders login form */

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      displayError: false
    };
    this.userChange = this.userChange.bind(this);
    this.passwordChange = this.passwordChange.bind(this);
    this.authenticate = this.authenticate.bind(this);
    this.hideError = this.hideError.bind(this);
  }

  // called when any change is made to username field input
  userChange(e) {
    e.preventDefault()
    this.setState({
      ...this.state,
      username: e.target.value
    });
  }

  // called when any change is made to password field input
  passwordChange(e) {
    e.preventDefault()
    this.setState({
      ...this.state,
      password: e.target.value
    });
  }

  // called when wrong password alert closed 
  hideError() {
    this.setState({
      ...this.state,
      displayError: false,
    });
  }

  async authenticate() {
    // use axios to hit authenticate endpoint
    let success = (await sendLoginRequest(this.state.username, this.state.password));
    if (!success) {
      //display wrong username/password message
      this.setState({
        ...this.state,
        displayError: true,
      });
    } else {
      let status = (await getAccountStatus())

      if (status === false) {
        console.log("failiure")
      }
      else if (status === 1)
        window.location.href = `${window.location.origin}/basicinfo`;
      else if (status === 2)
        window.location.href = `${window.location.origin}/lifestylesurvey`;
      else if (status === 3)
        window.location.href = `${window.location.origin}/bio`;
      else if (status === 4 && localStorage.getItem('first signup') === "true")
        window.location.href = `${window.location.origin}/photoupload`;
      else
        window.location.href = `${window.location.origin}/matching`;
    }
  }

  render() {
    return (
      <div id='login_page'>
        <div id='sign_in_card_arrangement'>
          {
            // displays error message if username/password wrong and not yet closed
            (this.state.displayError) &&
            <Alert variant="danger" onClose={this.hideError} className='signin_alert' dismissible>
              Username or Password entered is incorrect. Please make sure you've verified your email and try again.
            </Alert>
          }

          <div id='highlighted_card'>
            <div className='signup_title'> {this.props.title} </div>

            <form onSubmit={this.authenticate}>
              <label>
                <div id='signin_input_fields'>
                  <div>
                    <input
                      className="default_roomble_input_box"
                      name="username"
                      type="text"
                      placeholder=".edu Email Address"
                      value={this.state.username}
                      onChange={this.userChange}
                    />
                  </div>

                  <div>
                    <input
                      className="default_roomble_input_box"
                      name="password"
                      type="password"
                      placeholder="Password"
                      value={this.state.password}
                      onChange={this.passwordChange}
                    />
                  </div>
                </div>

                <div>
                  <input
                    className="default_roomble_button"
                    name="submit"
                    type="button"
                    disabled={!this.state.username || !this.state.password}
                    value={this.props.submitButton}
                    onClick={this.authenticate}
                  />
                </div>
              </label>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

async function sendLoginRequest(username, password) {
  try {
    var response = await axios.post(`${config.host}/LoginPost`, { username, password });
    if (response.status === 200) {
      localStorage.setItem('roomies_auth_token', response.data);
      console.log("cookie set!");
      return true;
    }
  } catch (error) {
    console.log('Wrong username or password')
  }
  return false;
}

/* Calls backend with user auth token to find if account has completed signup process */
async function getAccountStatus() {
  console.log("hit");
  try {
    var status = await axios.get(`${config.host}/GetAccountStatus`, {
      headers: {
        roomies_auth_token: localStorage.getItem("roomies_auth_token"),
      },
    });
    return status.data;
  } catch (err) {
    console.log("failed to create user");
    return false;
  }
}

export default Login;