import React from 'react'
import { Link } from 'react-router-dom'
import profile_icon from '../Logo and Art Files/profile_icon.svg';
import swipe_icon from '../Logo and Art Files/swipe_icon.svg';
import mutuals_icon from '../Logo and Art Files/mutuals_icon.svg';
import profile_icon_selected from '../Logo and Art Files/profile_icon_selected.svg';
import swipe_icon_selected from '../Logo and Art Files/swipe_icon_selected.svg';
import mutuals_icon_selected from '../Logo and Art Files/mutuals_icon_selected.svg';

/* Renders the bottom navbar component for direction bewteen pages */

class BottomNavbar extends React.Component {
  render() {
    return (
      <div id="bottom_navbar_space">

        <div id="bottom_navbar">
          {(this.props.page === "matches") && (<Link to="/matches"> <img src={mutuals_icon_selected} alt="Profile" className='bottom_navbar_svg' />  </Link>)}
          {(this.props.page !== "matches") && (<Link to="/matches"> <img src={mutuals_icon} alt="Profile" className='bottom_navbar_svg' /> </Link>)}
          {(this.props.page === "swipe") && <Link to="/matching"> <img src={swipe_icon_selected} alt="Profile" className='bottom_navbar_svg' /> </Link>}
          {(this.props.page !== "swipe") && <Link to="/matching"> <img src={swipe_icon} alt="Profile" className='bottom_navbar_svg' /> </Link>}
          {(this.props.page === "userprofile") && <Link to="/userprofile"> <img src={profile_icon_selected} stroke='red' alt="Profile" className='bottom_navbar_svg' /> </Link>}
          {(this.props.page !== "userprofile") && <Link to="/userprofile"> <img src={profile_icon} alt="Profile" className='bottom_navbar_svg' /> <profile_icon />  </Link>}
        </div>
      </div>
    );
  }
}

export default BottomNavbar;
