import { React, useEffect } from 'react';
import Signup from '../Components/Signup';
import config from '../config.js'
/* Renders page where user signs up */

function EntrancePage(props) {
  useEffect(() => {
    document.title = 'Signup';
  }, [])

  return (
    <div id="entrance_page">
      <div id='sign_in_page_arrangement'>
        <div>{props.changePage('entrance')}</div>

        <div id="signup_login_despacer"></div>

        <Signup
          title="Join Roomble Today."
          submitButton="Next" />

        <div id='signin_switch_pages_text'>
          No School Email?{' '}
          <a id='signin_help_link' href="https://unexonline.zendesk.com/hc/en-us/articles/360052749191-Create-and-Access-Your-UCLA-Email-Account">Create Yours Now</a>
        </div>
        <div id='signin_help_text'>
          Questions or trouble?{' '}
          <a id='signin_help_link' href="mailto:roomble.customer.service@gmail.com">Email Us</a>
        </div>
        <div id='signin_help_text'>
          Roombled Before?
          <a id='signin_help_link' href={`${window.location.origin}/login`}> Login</a>
        </div>
      </div>
    </div >
  )
}

export default EntrancePage;