import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../config.js';
import MailchimpSubscribe from "react-mailchimp-subscribe";

/* Render the CustomForm object with MailchimpSubscribe */
function MailchimpForm(props) {
  const postURL = "https://Roomble.us12.list-manage.com/subscribe/post?u=0a13b590ab551d4adb7218503&id=e877ed80f1";

  /* Creates custom form object to pass to mailchimp subscribe so the form formatting matches theme */
  function CustomForm({ status, message, onValidated }) {
    let [email, setEmail] = useState('');

    /* Calls clearFields if status just changed and is in a success state */
    useEffect(() => {
      if (status === "success") clearFields()
    }, [status])

    /* Sets email field value to empty string */
    const clearFields = () => {
      setEmail('');
    }
    /* Validates email and submits */
    const handleSubmit = async (e) => {
      e.preventDefault();
      let email_lower = email.toLowerCase();
      email &&
        email.indexOf("@") > -1 &&
        onValidated({
          MERGE0: email_lower
        });

      /* Redirect users to new school page if they enter .edu email we're not live for */

      /* If they enter a .edu email we're live for*/
      if (email_lower.endsWith(".edu") && config.liveSchools.some(school => email_lower.endsWith(school.emailExtension)))
      {
        localStorage.setItem('user_signup_email', email_lower);
        window.location.href = `${window.location.origin}/signup`;
      }

      /* If they enter a .edu email we aren't live for */
      else if (email_lower.endsWith(".edu")) {
        window.location.href = `${window.location.origin}/upcomingschool`;
      }

      /* Else they enter a non school email */

      /* Always store their email in the mail list */
      await AddToEmailList(email_lower)
    }
    /* Updates email field when user changes the value */
    function handleChange(e) {
      setEmail(e.target.value);
    }

    return (
      <div>
        <form onSubmit={handleSubmit}>
          <div>
            <input
              id="mailchimp_text_box"
              label="Email"
              type="email"
              onChange={handleChange}
              value={email}
              placeholder="Your School Email" />
          </div>
          <div id="mailchimp_submit_button_wrapper">
            <input
              id="mailchimp_submit_button"
              label="subscribe"
              type="button"
              onClick={handleSubmit}
              value="Start Roombling"
            />
          </div>
        </form>

        {status === "sending" && (
          <div className='mailchimp_message'>
            sending...
          </div>
        )}
        {status === "error" && (
          <div
            className='mailchimp_message'
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        {status === "success" && (
          <div className='mailchimp_message'> Please enter a .edu email to get started! If you're a parent or new student without an email, we'll keep you in the loop. </div>
        )}
      </div>
    );
  };

  return (
    <div>
      <MailchimpSubscribe
        url={postURL}
        render={({ subscribe, status, message }) => (
          <div>
            <CustomForm
              status={status}
              message={message}
              onValidated={formData => subscribe(formData)}
            />
          </div>
        )}
      />
    </div>
  );
}

async function AddToEmailList(email) {
  try {
    var response = await axios.post(`${config.host}/AddToEmailList`, { email });
    if (response.status === 200) {
      return true;
    }
  } catch (error) {
    console.log(error);
  }
  return false; 
}

export default MailchimpForm