let questions;
export default questions = [
    {
        question: "How often do you clean your room?",
        option1: "Everyday",
        option2: "Two to four times per week",
        option3: "Once a week",
        option4: "Every few weeks",
    },
    {
        question: "In what social environment are you most comfortable?",
        option1: "Large groups (7 or more people)",
        option2: "Small groups (3 to 6 people)",
        option3: "With one friend",
        option4: "By myself",
    },
    {
        question: "How friendly do you want to be with your roommate?",
        option1: "Best friends!",
        option2: "Close friends",
        option3: "Friends",
        option4: "Friendly enough",
    },
    {
        question: "Are you okay with sharing belongings?",
        option1: "For most of my things",
        option2: "For some of my less personal items",
        option3: "For the most basic items",
        option4: "No",
    },
    {
        question: "What noise level do you prefer during the day?",
        option1: "Conversation and music is fine",
        option2: "Some noise is okay",
        option3: "Generally quiet",
        option4: "As quiet as possible",
    },
    {
        question: "What noise level do you prefer on a school night?",
        option1: "Conversation and music is fine",
        option2: "Some noise is okay",
        option3: "Generally quiet",
        option4: "As quiet as possible",
    },
    {
        question: "What time do you go to bed on a school night?",
        option1: "Before 11pm",
        option2: "11pm-12am",
        option3: "12am-1am",
        option4: "After 1am",
    },
    {
        question: "What temperature do you prefer when sleeping? (°F)",
        option4: "Below 69",
        option3: "69-70.9",
        option2: "71-72.9",
        option1: "73 or higher",
    },
    {
        question: "How often do you have overnight guests?",
        option1: "More than once a week",
        option2: "More than 5 times per quarter or semester",
        option3: "1 to 2 times per quarter or semester",
        option4: "Never"
    },
    {
        question: "How often do you plan to go out in the evenings?",
        option1: "Weeknights and Weekends",
        option2: "Just weekends",
        option3: "1-2 times a month",
        option4: "Almost never"
    },
    {
        question: "How often do you drink?",
        option1: "Weeknights and Weekends",
        option2: "Just weekends",
        option3: "1-2 times a month",
        option4: "Never"
    },
    {
        question: "How often do you smoke?",
        option1: "Weeknights and Weekends",
        option2: "Just weekends",
        option3: "1-2 times a month",
        option4: "Never"
    },
];
