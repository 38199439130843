import { React, useEffect, useState, useRef } from 'react';
import axios from 'axios';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import ReactGA from "react-ga4";
import config from '../config.js';

/* Renders edit photo form and gets current photo from databse to display upon return */

function PhotoUpload(props) {
  //true if a user is going thorugh signup process
  const [newUser, setNewuser] = useState(false)
  //hold original uncropped image
  const [srcImg, setSrcImg] = useState(null)
  //hold image ref that's actually cropped
  const imgRef = useRef(null)
  //change the aspect ratio of crop tool as you preferred
  const [crop, setCrop] = useState({
    unit: "px",
    x: 0,
    y: 0,
    width: 100,
    height: 100,
  });
  //hold image crop url
  const [cropUrl, setCropUrl] = useState(null);
  //save the resulted image
  const [result, setResult] = useState(null);
  const [hasUploadedImage, setHasUploadedImage] = useState(false)
  //check if user has already uploaded image
  useEffect(async () => {
    var oldImage = await getProfile();
    console.log(oldImage.images);
    if (oldImage.images.length === 0) { setHasUploadedImage(false); }
    else { setHasUploadedImage(true); }

    let status = (await getAccountStatus())
    if (status !== 5)
      setNewuser(true)
    document.title = 'Photo Upload';
  }, [])


  const handleImage = (event) => {
    setSrcImg(URL.createObjectURL(event.target.files[0]));
    setResult(null)
    setCrop({
      unit: "px",
      x: 0,
      y: 0,
      width: 100,
      height: 100,
    })
  };

  function getCroppedImageNew() {
    // creating the cropped image from the source image
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio
    const scaleX = imgRef.current.naturalWidth / imgRef.current.width;
    const scaleY = imgRef.current.naturalHeight / imgRef.current.height;
    canvas.width = Math.floor(crop.width * pixelRatio)
    canvas.height = Math.floor(crop.height * pixelRatio)
    ctx.scale(pixelRatio, pixelRatio)
    ctx.imageSmoothingQuality = 'high'
    ctx.drawImage(
      imgRef.current,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          // returning an error
          if (!blob) {
            reject(new Error('Canvas is empty'));
            return;
          }

          blob.name = "croppedImage.png";
          // creating a Object URL representing the Blob object given
          const croppedImageUrl = window.URL.createObjectURL(blob);

          // resolve(croppedImageUrl);
          resolve([croppedImageUrl, blob]);
        }
      );
    });
  }

  async function cropImage() {
    if (imgRef && crop.width && crop.height) {
      const croppedImageInfo = await getCroppedImageNew();
      console.log(croppedImageInfo)
      const croppedImageBlob = croppedImageInfo[1]
      const croppedImageUrl = croppedImageInfo[0]
      // calling the props function to expose
      // croppedImage to the parent component
      setResult(croppedImageBlob);
      setCropUrl(croppedImageUrl);
    }
  }

  async function handleSubmit() {
    await UploadPhoto(result);
    ReactGA.event({
      category: "Update Profile",
      action: "Photo Upload Update",
      nonInteraction: false,
    });

    if (localStorage.getItem('first signup') === "true") {
      ReactGA.event({
        category: "Signup Process",
        action: "Finish Signup Process",
        nonInteraction: false,
      });
    }
    returnToProfile()
  }

  function returnToProfile() {
    localStorage.removeItem('first signup') //so bio won't send to photos again
    window.location.href = `${window.location.origin}/userprofile`;
  }

  if (localStorage.getItem('roomies_auth_token') == null) {
    window.location.href = `${window.location.origin}/`;
    return;
  }

  return (
    <div id="photo_upload_page">
      <div>{props.changePage('photoupload')}</div>
      {localStorage.getItem("first signup") === "true" &&
        <div>
          <div id="highlighted_card">
            <div className='signup_title'>
              You Did It! 👏
            </div>
            <div className='signup_subtext'>
              Take a bow and post a picture here.
            </div>
            <div className='signup_subtext'>
              You can always change your photo later.
            </div>
          </div>
          <div id="bio_spacer"></div>
        </div>
      }

      <div id="card">
        <div className='card_header'> Post a Picture 🖼️</div>
        <div className='card_text'> First upload an image <br /> Then drag to crop <br />  Lastly submit your photo </div>
        {srcImg && (
          <div className='crop_ui_box'>
            <ReactCrop
              className='crop_ui'
              crop={crop}
              aspect={1}
              onChange={c => setCrop(c)}>
              <img
                src={srcImg}
                ref={imgRef}
                alt="srcImg"
              />

            </ReactCrop>
          </div>
        )}
      </div>
      {srcImg && (
        <div>
          <button
            className='highlighted_roomble_button'
            id='photo_upload_submit_button'
            onClick={cropImage}> Crop
          </button>
        </div>
      )}
      <label>
        <div className='highlighted_roomble_button' id="upload_photo_button"> Upload Photo </div>
        <input
          id="pics_upload_icon"
          name="photo_upload"
          type="file"
          accept='image/*'
          onChange={handleImage}
        />
      </label>
      <div>
        <button
          className='highlighted_roomble_button'
          id='photo_upload_submit_button'
          onClick={returnToProfile}> {!newUser && "Cancel"} {newUser && "Skip for now"}
        </button>
      </div>

      {
        result && (
          <div>
            <div className="photo_upload_spacer"></div>
            <div id="card">
              <div className='card_header'> Preview </div>
              <div className='card_text'> This is how your image will look to other Roomblers! </div>
              <div className='cropped_thumbnail_box'>
                <img className='cropped_thumbnail' src={cropUrl} alt="cropped result" />
              </div>

            </div>
          </div>
        )
      }

      {
        result && (
          <div>
            <button
              className='highlighted_roomble_button'
              id='photo_upload_submit_button'
              name="submit_photo_upload"
              value="Submit"
              onClick={handleSubmit}
              disabled={!result}
            > Submit </button>
          </div>
        )
      }
      <div className='page_bottom_spacer'></div>
    </div >
  )
}


// calls backend with login input 
async function UploadPhoto(image) {
  try {
    var formData = new FormData();
    formData.append('image', image, image.name);
    var response = await axios.post(`${config.host}/UploadImages`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        roomies_auth_token: localStorage.getItem("roomies_auth_token")
      }
    });

    if (response.status === 200) {
      return true;
    }
  } catch (error) {
    // logs error message
    console.log(error);
    console.log('image upload failed')
  }
  return false;
}

async function getProfile() {
  try {
    var res = await axios.get(`${config.host}/GetProfileById`, {
      headers: {
        roomies_auth_token: localStorage.getItem("roomies_auth_token"),
      }
    });
    return res.data;
  } catch (err) {
    console.log(err);
    return null;
  }
}

/* Calls backend with user auth token to find if account has completed signup process */
async function getAccountStatus() {
  console.log("hit");
  try {
    var status = await axios.get(`${config.host}/GetAccountStatus`, {
      headers: {
        roomies_auth_token: localStorage.getItem("roomies_auth_token"),
      },
    });
    return status.data;
  } catch (err) {
    console.log("failed to create user");
    return false;
  }
}

export default PhotoUpload;