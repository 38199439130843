import axios from 'axios';
import { React, useEffect, useState } from 'react';
import LifestyleSurvey from '../Components/LifestyleSurvey';
import config from '../config.js';

/* Gets profile data to pass to lifestyle survey component and renders as page */

function LifestyleSurveyPage(props) {
  let [answers, setAnswers] = useState([]);
  let [displaySurvey, setDisplaySurvey] = useState(false);

  useEffect(async () => {
    // get survey questions and get profile
    var oldAnswers = await getAnswers();
    if (oldAnswers == null) oldAnswers = [];
    setAnswers(oldAnswers);
    setDisplaySurvey(true);
    document.title = 'Prefs and Past Times';
  }, [])

  if (localStorage.getItem('roomies_auth_token') == null) {
    window.location.href = `${window.location.origin}/`;
    return;
  }

  return (
    <div>
      <div> {props.changePage("lifestylesurvey")}</div>
      {displaySurvey && <LifestyleSurvey old_answers={answers} />}
    </div>
  )
}

async function getAnswers() {
  try {
    var res = await axios.get(`${config.host}/GetSurveyResponse`, {
      headers: {
        roomies_auth_token: localStorage.getItem("roomies_auth_token"),
      }
    });
    return res.data;
  } catch (err) {
    console.log(err);
    return null;
  }
}

export default LifestyleSurveyPage;