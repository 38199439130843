import { React, useEffect } from 'react';

/* Page user gets sent to once they signup and still need to validate their email */

function ValidateEmailPage(props) {
  useEffect(() => {
    document.title = 'Roomble';
  }, [])
  return (
    <div>
      <div>{props.changePage('validateemail')}</div>
      <div id="highlighted_card">
        <div className='informational_header'> Check your email! <br /> (And your spam :O)</div>
        <div className='informational_text'> We sent you an email with a link to confirm your .edu address. </div>
        <div className='informational_text'> This ensures only validated students are on Roomble. </div>
      </div>
    </div>
  )
}

export default ValidateEmailPage;