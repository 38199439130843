import { React, useState, useEffect } from 'react';
import config from '../config.js'

function BurgerMenu() {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    // stops page from being scrollable if burger menu is open
    const body = document.querySelector('body');
    if (open) {
      body.classList.add('no-scroll');
    } else {
      body.classList.remove('no-scroll');
    }
  }, [open]);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <div className="landing_page_burger_menu">
      <div className={`menu ${open ? 'open' : ''}`}> {/* conditionally applies open class to menu */}
        <span><a href={`${window.location.origin}/login`}>Login</a></span>
        <a href="https://linktr.ee/roomble">Learn More</a>
        <a href="mailto:roomble.customer.service@gmail.com">Help</a>
      </div>
      <div className={`burger ${open ? 'open' : ''}`} onClick={handleClick}>
        <div className="burger_line"></div>
        <div className="burger_line"></div>
        <div className="burger_line"></div>
      </div>
    </div>
  );
}

export default BurgerMenu;
