import BasicInfo from '../Components/BasicInfo';
import axios from 'axios';
import { React, useEffect, useState } from 'react';
import config from '../config.js';

/* Gets profile data to pass to basic info component and render as a page */

function BasicInfoPage(props) {
  let [oldBasicInfo, setOldBasicInfo] = useState([]);
  let [displayBasicInfo, setDisplayBasicInfo] = useState(false);

  useEffect(async () => {
    // get survey questions and get profile
    var oldUserInfo = await getProfile();
    if (oldUserInfo === "") {
      localStorage.setItem('first signup', true) // So bio knows to send you to photos
      var institution = await getInstitution();
      localStorage.setItem('institution', institution); // So we know what housing to show on first signup
    };
    setOldBasicInfo(oldUserInfo);
    setDisplayBasicInfo(true);

    document.title = 'Facts & Figures';
  }, [])

  if (localStorage.getItem('roomies_auth_token') == null) {
    window.location.href = `${window.location.origin}/`;
    return;
  }

  return (
    <div>
      <div> {
        props.changePage('basicinfo')
      }</div>

      {localStorage.getItem('first signup') === "true" &&
        <div>
          <div id="highlighted_card">
            <div className='signup_title'>
              🎉 Congrats!
            </div>
            <div className="signup_subtext">
              We got it from here. Help us get started.
            </div>
            <div className='signup_subtext'>
              You can always change this info later.
            </div>
          </div>
          <div id="bio_spacer"></div>
        </div>
      }

      <div id="highlighted_card">
        <div className="signup_title"> Facts & Figures ✍️</div>
        <div className="signup_subtext" id="basic_info_subtext"> Provide your info for tomorrow's roommates! </div>
        {displayBasicInfo && <BasicInfo old_answers={oldBasicInfo} />}
      </div>
    </div>
  )
}

async function getProfile() {
  try {
    var res = await axios.get(`${config.host}/GetProfileById`, {
      headers: {
        roomies_auth_token: localStorage.getItem("roomies_auth_token"),
      }
    });
    return res.data;
  } catch (err) {
    console.log(err);
    return null;
  }
}

async function getInstitution() {
  try {
    var res = await axios.get(`${config.host}/GetInstitution`, {
      headers: {
        roomies_auth_token: localStorage.getItem("roomies_auth_token"),
      }
    });
    return res.data;
  } catch (err) {
    console.log(err);
    return null;
  }
}

export default BasicInfoPage;