import React from 'react';
import styles from './Checkbox.module.css';

/**
 * Render a checkbox component.
 *
 * @param {Object} props - The properties for the checkbox component.
 * @param {boolean} props.checked - Whether the checkbox is checked.
 * @param {boolean} props.defaultChecked - Whether the checkbox is initially checked.
 * @param {function} props.onChange - The function to call when the checkbox value changes.
 * @param {string} props.label - The label for the checkbox.
 * @param {string} props.name - The name of the checkbox.
 * @param {string} props.value - The value of the checkbox.
 * @param {boolean} props.disabled - Whether the checkbox is disabled.
 * @param {string} props.id - The ID of the checkbox.
 * @return {JSX.Element} The rendered checkbox component.
 */
function Checkbox({
    checked,
    defaultChecked,
    onChange,
    label,
    name,
    value,
    disabled,
    id,
}) {
    const containerClass = label
        ? `${styles.roomble_checkbox_container} ${styles.roomble_checkbox_container_with_label}`
        : styles.roomble_checkbox_container;

    return (
        // Create a container div for the checkbox component
        <div className={containerClass}>
            <div>
                <input
                    type="checkbox"
                    name={name}
                    value={value}
                    checked={checked}
                    defaultChecked={defaultChecked}
                    onChange={onChange}
                    disabled={disabled}
                    id={id}
                />
            </div>
            <div>
                {/* Render the checkbox label if provided */}
                {label && <div> {label} </div>}
            </div>
        </div>
    );
}

export default Checkbox;