import { React, useEffect } from 'react';
/* Page user gets sent to once they signup and still need to validate their email */

function WebsiteDownPage(props) {
    useEffect(() => {
        document.title = "Website Down"
    }, [])
    return (
        <div>
            <div>{props.changePage('websitedown')}</div>
            <div id="highlighted_card">
                <div className='informational_header'> Roomble is under construction right now!</div>
                <div className='informational_text'> We'll be back up as soon as possible. </div>
                <div className='informational_text'> If you have any questions or issues you can email us at:
                    roomble.customer.service@gmail.com</div>
            </div>
        </div>
    )
}

export default WebsiteDownPage;