import React from 'react';
import { Form, Alert } from 'react-bootstrap'
import axios from 'axios';
import classNames from "classnames";
import questions from '../jsons/lifestyleSurveyQuestions';
import ReactGA from "react-ga4";
import config from '../config.js';

class Survey extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      questions,
      displayError: false,
      hasSubmitted: false,
      // initailize these dynamically
      old_answers: Array(questions.length), // get from back if on profile page, null if first survey 
      new_answers: Array(questions.length),
      new_weights: Array(questions.length),
      hasOldAnswers: false,
      currentQuestion: 0,
    };
    if (props.hasOwnProperty("old_answers") && props.old_answers != null && props.old_answers.length > 0) {
      var newAnswers = [];
      var newWeights = [];
      for (let i = 0; i < props.old_answers.length; i++) {
        var answer = props.old_answers[i];
        newAnswers.push(answer.response_value + '');
        newWeights.push(answer.preference_weight + '');
      }
      this.state = {
        ...this.state,
        old_answers: props.old_answers,
        hasOldAnswers: true,
        new_answers: newAnswers,
        new_weights: newWeights,
      };
    }
    this.onAnswerChange = this.onAnswerChange.bind(this);
    this.onWeightChange = this.onWeightChange.bind(this);
    this.validateAnswers = this.validateAnswers.bind(this);
    this.hideError = this.hideError.bind(this);
    this.changeQuestion = this.changeQuestion.bind(this);
    this.submit = this.submit.bind(this);
  }

  onAnswerChange(event, qid) {
    event.preventDefault();
    const copyNewAnswers = this.state.new_answers;
    copyNewAnswers[qid] = event.target.value;
    this.setState({
      ...this.state,
      new_answers: copyNewAnswers
    })
  }

  onWeightChange(event, qid) {
    const copyNewWeights = this.state.new_weights;
    copyNewWeights[qid] = event.target.value;
    this.setState({
      ...this.state,
      new_weights: copyNewWeights,
    })
  }

  /* Submits lifestyle survey after validation and moves user to next page */
  async submit() {
    if (this.validateAnswers()) {
      var promises = [];
      for (let i = 0; i < this.state.new_weights.length; i++) {
        let answer = parseInt(this.state.new_answers[i]);
        let weight = parseInt(this.state.new_weights[i]);
        promises.push(sendQuestion(i, answer, weight))
      }
      await Promise.all(promises);
      ReactGA.event({
        category: "Update Profile",
        action: "Lifestyle Survey Update",
        nonInteraction: false,
      });
      if (localStorage.getItem("first signup") === "true")
        window.location.href = `${window.location.origin}/bio`;
      else
        window.location.href = `${window.location.origin}/userprofile`;
    } else {
      window.scrollTo(0, 0);
      this.setState({
        ...this.state,
        displayError: true,
        hasSubmitted: true
      })
    }
  }

  /* Ensures the entire form is filled out */
  validateAnswers() {
    for (let i = 0; i < this.state.new_weights.length; i++) {
      if (this.state.new_weights[i] == null ||
        this.state.new_weights[i] === '') return false;
      if (this.state.new_answers[i] == null ||
        this.state.new_answers[i] === '') return false;
    }
    return true;
  }

  hideError() {
    this.setState({
      ...this.state,
      displayError: false,
    })
  }

  changeQuestion(event, change) {
    event.preventDefault();
    if (this.state.currentQuestion + change > 11 || this.state.currentQuestion + change < 0)
      return;
    this.setState({
      ...this.state,
      currentQuestion: this.state.currentQuestion + change,
    })
  }

  render() {
    var questionaire = [];
    /* Iterates over all questions & weights and renders & pushes each as an item in array to 
    questionare */
    for (let i = 0; i < this.state.questions.length; i++) {
      const question = this.state.questions[i];
      let defaultValueAnswer = '';
      // if we have old answers fill it with old answers
      if (this.state.hasOldAnswers === true && i < this.state.old_answers.length) {
        defaultValueAnswer = this.state.old_answers[i].response_value + '';
      }
      // if we don't have old answers check if they've already answered the question and if so fill it with that
      if (this.state.hasOldAnswers === false && this.state.new_answers[i] != null) {
        defaultValueAnswer = this.state.new_answers[i]
      }

      questionaire.push(
        <div className='survey_form_item form-group' key={i}>
          <div className='lifestyle_prefs_title_box'>
            <label className='lifestyle_prefs_title'> {question.question}</label>
          </div>
          <div>
            <select defaultValue={defaultValueAnswer} className={classNames({
              "default_roomble_input_box": true,
              "default_roomble_input_box_error": (this.state.hasSubmitted !== false && this.state.new_answers[i] == null)
            })} onChange={(e) => this.onAnswerChange(e, i)}>
              <option value='' disabled>Answer</option>
              <option value='0'>{question.option1}</option>
              <option value='1'>{question.option2}</option>
              <option value='2'>{question.option3}</option>
              <option value='3'>{question.option4}</option>
            </select>
          </div>

          <div id="lifestyle_weights_outer_box">
            <label className='lifestyle_prefs_title'> How important is it your roommate shares this trait?</label>
            <div id="lifestyle_horizontal_box">
              <div id="lifestyle_radio_button_label_box">
                <input
                  type="radio"
                  name={"weight" + i}
                  value="1"
                  checked={this.state.new_weights[i] === "1"}
                  onChange={(e) => this.onWeightChange(e, i)}>
                </input>
                <label id='lifestyle_radio_label'> Very </label>
              </div>

              <div id="lifestyle_radio_button_label_box">
                <input
                  type="radio"
                  name={"weight" + i}
                  value="2"
                  checked={this.state.new_weights[i] === "2"}
                  onChange={(e) => this.onWeightChange(e, i)}>
                </input>
                <label id='lifestyle_radio_label'> Somewhat </label>
              </div>

              <div id="lifestyle_radio_button_label_box">
                <input
                  type="radio"
                  name={"weight" + i}
                  value="3"
                  checked={this.state.new_weights[i] === "3"}
                  onChange={(e) => this.onWeightChange(e, i)}>
                </input>
                <label id='lifestyle_radio_label'> Not </label>
              </div>
            </div>

            <div id='lifestyle_arrows_question_count_box'>
              <button className={classNames({
                lifestyle_arrow_box: true,
                lifestyle_arrow_box_highlighted: this.state.currentQuestion > 0,
                lifestyle_arrow_box_disabled: this.state.currentQuestion === 0,
              })} onClick={(e) => this.changeQuestion(e, -1)} disabled={this.state.currentQuestion === 0}>
                ←
              </button>

              <div className='signup_subtext'>
                Question {i + 1} of 12
              </div>

              <button className={classNames({
                lifestyle_arrow_box: true,
                lifestyle_arrow_box_highlighted: this.state.currentQuestion < 11,
                lifestyle_arrow_box_disabled: this.state.currentQuestion === 11,
              })} onClick={(e) => this.changeQuestion(e, 1)} disabled={this.state.currentQuestion === 11}>
                →
              </button>
            </div>
          </div>
        </div >
      )
    }

    return (
      <div id="lifestyle_survey_page">

        {localStorage.getItem('first signup') === 'true' &&
          <div>
            <div id="highlighted_card">
              <div className='signup_title'>
                Keep it up!
              </div>
              <div className="signup_subtext">
                This is so you can find a great roommate.
              </div>
              <div className='signup_subtext'>
                You can always change this info later.
              </div>
            </div>

            <div id="bio_spacer"></div>
          </div>
        }
        {(this.state.displayError) && <Alert variant="danger" onClose={this.hideError} dismissible>Please answer all the questions</Alert>}
        <div id="highlighted_card">
          <div className="signup_title"> Prefs & Past Times ⏳ </div>
          <div className="signup_subtext"> Give us the inside scoop so we can find you a roommate who's a great fit to live with.</div>
          <Form>
            {questionaire[this.state.currentQuestion]}
            <button disabled={this.validateAnswers() === false} onClick={this.submit} id='lifestyle_submit_button' className='default_roomble_button' type="button">Submit</button>
          </Form>
        </div>

        <div className='page_bottom_spacer'></div>
      </div>
    );
  }
}

/* Sends individual question to backend to update */
async function sendQuestion(qid, answer, weight) {
  return new Promise(async (resolve, reject) => {
    try {
      await axios.get(`${config.host}/AnswerQuestion?question_id=${qid}&answer=${answer}&preference=${weight}`, {
        headers: {
          roomies_auth_token: localStorage.getItem("roomies_auth_token"),
        },
      });
      resolve();
    } catch (err) {
      console.log(err);
      reject();
    }
  })
}

export default Survey;
