import React from 'react';
import styles from "./ToggleSwitch.module.css";

function ToggleSwitch({
    checked,
    defaultChecked,
    onChange,
    label,
    name,
    value,
    disabled,
    id,
}) {
    return (
        <label className={styles.toggleSwitchContainer}>
            <div>
                <input
                    className={styles.toggleSwitchInput}
                    type="checkbox"
                    name={name}
                    value={value}
                    checked={checked}
                    defaultChecked={defaultChecked}
                    onChange={onChange}
                    disabled={disabled}
                />
                <div className={styles.toggleSwitchIcon}>
                    <img className={styles.toggleSwitchIconLeft} src='/sendingMailIcon.svg' alt="sending mail" />
                    <img className={styles.toggleSwitchIconRight} src='/doNotDisturbIcon.svg' alt="do not disturb" />
                </div>
                <div className={styles.toggleSwitchHandle}>
                    {label}
                </div>
            </div>
        </label>
    );
}

export default ToggleSwitch;